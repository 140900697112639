import axios from "axios"
import { API_URL } from "../config"

const instance = axios.create({
  baseURL: API_URL
})

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${window.localStorage.getItem(
    "token"
  )}`

  return config
})

export default instance
