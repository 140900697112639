import React from "react"
import { Link } from "react-router-dom"
import { Edit, Trash2, Pause, Play } from "lucide-react"

const Audio = ({
  audio,
  onChangeAudio,
  currentAudio,
  onClickShowDeleteModal
}) => {
  return (
    <>
      <tr className="intro-x">
        <td className="!py-3.5">
          <div className="flex items-center">
            <div className="w-9 h-9 image-fit zoom-in">
              <img
                alt=""
                className="rounded-lg border-white shadow-md tooltip"
                src={audio?.image}
              />
            </div>
            <div className="ml-4">
              <Link
                to={`/audio-list/${audio?.id}`}
                className="font-medium whitespace-nowrap"
              >
                {audio?.title}
              </Link>
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {audio?.category.name}
              </div>
            </div>
          </div>
        </td>

        <td className="w-10">
          {currentAudio === audio?.url ? (
            <button
              className="btn btn-primary"
              onClick={() => onChangeAudio("")}
            >
              <Pause className="w-4 h-4"></Pause>
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => onChangeAudio(audio?.url)}
            >
              <Play className="w-4 h-4"></Play>
            </button>
          )}
        </td>

        <td className="table-report__action w-72">
          <div className="flex justify-center items-center">
            <Link
              to={`/audio-list/${audio?.id}`}
              className="flex items-center mr-3"
            >
              <Edit className="w-4 h-4 mr-1"></Edit> Изменить
            </Link>

            <button
              className="flex items-center text-danger"
              onClick={() => onClickShowDeleteModal(audio?.id)}
            >
              <Trash2 className="w-4 h-4 mr-1"></Trash2> Удалить
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default Audio
