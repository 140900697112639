import React, { useEffect, useState } from "react"
import { Palette, X } from "lucide-react"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { SketchPicker } from "react-color"

import InvalidFeedback from "../../../components/InvalidFeedback"
import { deleteSocial, updateSocial } from "../../../../redux/slices/about"

const Modal = ({ onClickItem, showModal }) => {
  const dispatch = useDispatch()
  const [sketchColor, setSketchColor] = useState("#05A6FF")
  const [showColorPicker, setShowColorPicker] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    dispatch(updateSocial(data))
    onClickItem(null)
  }

  const onClickDelete = (id) => {
    dispatch(deleteSocial(id))
    onClickItem(null)
  }

  useEffect(() => {
    reset(showModal)
    setSketchColor(showModal?.color)
  }, [showModal])

  return (
    <div className="modal overflow-y-auto show">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="font-medium text-md mr-auto truncate">
              Редактирование социальной сети
            </h2>
            <button data-tw-dismiss="modal" onClick={() => onClickItem(null)}>
              <X className="w-8 h-8 text-slate-400" />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <div className="col-span-12 sm:col-span-12">
                <div>
                  <label className="form-label">Название</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите название"
                    name="title"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <InvalidFeedback text={"Введите описание"} />
                  )}
                </div>

                <div className="mt-4">
                  <label className="form-label">Ссылка</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите название"
                    name="title"
                    {...register("url", { required: true })}
                  />
                  {errors.url && <InvalidFeedback text={"Введите описание"} />}
                </div>
              </div>

              <div className="col-span-12 sm:col-span-6">
                <div className="mt-4">
                  <label className="form-label">Цвет</label>
                  <div className="cursor-pointer relative flex items-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary shadow-md mr-2"
                      onClick={() => setShowColorPicker(!showColorPicker)}
                    >
                      <Palette className="w-5 h-5 mr-2"></Palette> Выбрать цвет
                    </button>
                    <button
                      type="button"
                      className="btn w-8 h-8"
                      style={{ backgroundColor: sketchColor }}
                    ></button>
                  </div>
                  {showColorPicker && (
                    <SketchPicker
                      className="mt-2"
                      color={sketchColor}
                      onChange={(e) => setSketchColor(e.hex)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger w-24 mr-3"
                onClick={() => onClickDelete(showModal?.id)}
              >
                Удалить
              </button>
              <button type="submit" className="btn btn-primary w-28">
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Modal
