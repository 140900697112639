import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { BarChart2, XCircle } from "lucide-react"
import { NavLink, useNavigate } from "react-router-dom"

import logo from "../../../assets/images/logo/logo-no-bg.svg"
import { publicMenu, privateMenu } from "../../../utils/navMenu"

const MobileMenu = () => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const [isMenuActive, setIsMenuActive] = useState(false)

  const renderSidebarMenu = () => {
    if (
      user?.role?.type === "administrator" ||
      user?.role?.type === "superadmin"
    ) {
      return privateMenu.map((item, i) => (
        <li key={i}>
          {item?.sub ? (
            <>
              <NavLink to={item.sub[0].path} className="menu">
                <div className="menu__icon">{item.icon}</div>
                <div className="menu__title">{item.title}</div>
              </NavLink>
            </>
          ) : (
            <NavLink to={item.path} className="menu">
              <div className="menu__icon">{item.icon}</div>
              <div className="menu__title">{item.title}</div>
            </NavLink>
          )}
        </li>
      ))
    } else {
      return publicMenu.map((item, i) => (
        <li key={i}>
          <NavLink to={item.path} className="menu">
            <div className="menu__icon">{item.icon}</div>
            <div className="menu__title">{item.title}</div>
          </NavLink>
        </li>
      ))
    }
  }

  useEffect(() => {
    setIsMenuActive(false)
  }, [navigate])

  return (
    <>
      <div
        class={`mobile-menu md:hidden ${
          isMenuActive ? "mobile-menu--active" : ""
        }`}
      >
        <div class="mobile-menu-bar">
          <div class="flex mr-auto">
            <img alt="logo" class="w-9" src={logo} />
          </div>
          <button
            class="mobile-menu-toggler"
            onClick={() => setIsMenuActive(true)}
          >
            <BarChart2 class="w-8 h-8 text-white transform -rotate-90"></BarChart2>
          </button>
        </div>
        <div class="scrollable">
          <button
            class="mobile-menu-toggler"
            onClick={() => setIsMenuActive(false)}
          >
            <XCircle class="w-8 h-8 text-white transform -rotate-90"></XCircle>
          </button>
          <ul class="scrollable__content py-2">{renderSidebarMenu()}</ul>
        </div>
      </div>
    </>
  )
}

export default MobileMenu
