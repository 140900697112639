import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Plus, Search } from "lucide-react"
import { Link } from "react-router-dom"
import Book from "./Book"
import DeleteModal from "./DeleteModal"
import { getBooks, getCategories } from "../../../../redux/slices/book"

const BookList = () => {
  const dispatch = useDispatch()
  const { books } = useSelector((state) => state.book)
  const [searchValue, setSearchValue] = useState("")
  const [currentBook, setCurrentBook] = useState("")
  const [removeBook, setRemoveBook] = useState(null)

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value)
  }

  const onChangeBook = (bookLink) => {
    setCurrentBook(bookLink)
  }

  const onClickShowDeleteModal = (book) => {
    setRemoveBook(book)
  }

  useEffect(() => {
    dispatch(getBooks())
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <h2 className="text-lg font-medium mr-auto mt-2">Список книг</h2>
        </div>
        <div className="intro-y col-span-12 flex flex-wrap xl:flex-nowrap items-center mt-2">
          <div className="flex w-full sm:w-auto">
            <div className="w-54 relative text-slate-500">
              <input
                type="text"
                className="form-control w-54 box pr-10"
                placeholder="Найти книгу..."
                value={searchValue}
                onChange={onChangeSearchValue}
              />
              <Search className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"></Search>
            </div>
          </div>
          <div className="hidden xl:block mx-auto text-slate-500">
            Всего книг: {books?.length}
          </div>
          <div className="w-full xl:w-auto flex flex-wrap xl:flex-nowrap items-center gap-y-3 mt-3 xl:mt-0">
            <Link to="/book-create" className="btn btn-primary shadow-md mr-2">
              <Plus className="w-4 h-4 mr-2"></Plus> Добавить книгу
            </Link>
          </div>
        </div>

        {books?.length ? (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <table className="table table-report -mt-2">
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Название книги</th>
                  <th className="text-center whitespace-nowrap">Смотреть</th>
                  <th className="text-center whitespace-nowrap">Изменить</th>
                </tr>
              </thead>
              <tbody>
                {books
                  ?.filter((book) => {
                    return book.title
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  })
                  ?.map((book, index) => (
                    <Book
                      key={index}
                      book={book}
                      onChangeBook={onChangeBook}
                      currentBook={currentBook}
                      onClickShowDeleteModal={onClickShowDeleteModal}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <div className="alert alert-primary show mb-2 mt-2">
              <div>Книг пока нет...</div>
            </div>
          </div>
        )}
      </div>

      {removeBook && (
        <DeleteModal
          onClickShowDeleteModal={onClickShowDeleteModal}
          removeBook={removeBook}
        />
      )}
    </>
  )
}

export default BookList
