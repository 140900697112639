import React, { useState } from "react"
import { Contact, FileText, Landmark } from "lucide-react"
import CarouselMessages from "./CarouselMessages"
import RequisitesTab from "./RequisitesTab"
import SocialsTab from "./SocialsTab"
import GeneralTab from "./GeneralTab"
import Modal from "./Modal"
import Stats from "./Stats"

const About = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [showModal, setShowModal] = useState(null)

  const onClickItem = (data) => {
    setShowModal(data)
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 2xl:col-span-9 lg:col-span-9 mt-8">
              <Stats />
            </div>
            <div className="col-span-12 2xl:col-span-3 lg:col-span-3 mt-8">
              <CarouselMessages />
            </div>

            <div className="col-span-12">
              <div className="intro-y flex flex-col sm:flex-row items-center mt-5">
                <h2 className="text-lg font-medium mr-auto">
                  Редактирование информации "О нас"
                </h2>
              </div>
              <div className="pos intro-y grid grid-cols-12 gap-5 mt-5">
                <div className="intro-y col-span-12 lg:col-span-12">
                  <div className="post intro-y overflow-hidden box">
                    <ul className="post__tabs nav nav-tabs flex-col sm:flex-row bg-slate-200 dark:bg-darkmode-800">
                      <li className="nav-item">
                        <button
                          className={`nav-link tooltip w-full sm:w-52 py-4 ${
                            activeTab === 1 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(1)}
                        >
                          <FileText className="w-4 h-4 mr-2" />
                          Основные данные
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link tooltip w-full sm:w-52 py-4 ${
                            activeTab === 2 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(2)}
                        >
                          <Landmark className="w-4 h-4 mr-2"></Landmark>{" "}
                          Реквизиты
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link tooltip w-full sm:w-52 py-4 ${
                            activeTab === 3 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(3)}
                        >
                          <Contact className="w-4 h-4 mr-2"></Contact>
                          Социальные сети
                        </button>
                      </li>
                    </ul>
                    <div className="post__content tab-content">
                      {activeTab === 2 ? (
                        <RequisitesTab />
                      ) : activeTab === 3 ? (
                        <SocialsTab onClickItem={onClickItem} />
                      ) : (
                        <GeneralTab />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && <Modal showModal={showModal} onClickItem={onClickItem} />}
    </>
  )
}

export default About
