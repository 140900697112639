import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getBooks = createAsyncThunk("books/getBooks", async (thunkAPI) => {
  try {
    const { data } = await axios.get("/book/index.php?count=200", {
      headers: {
        "Accept-Language": "ru"
      }
    })
    return data.result
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const getCategories = createAsyncThunk(
  "books/getCategories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/category/index.php?category=book", {
        headers: {
          "Accept-Language": "ru"
        }
      })
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteBook = createAsyncThunk(
  "books/deleteBook",
  async (bookData, thunkAPI) => {
    try {
      const { data } = await axios.delete("/book/index.php", {
        data: bookData
      })

      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createBook = createAsyncThunk(
  "books/createBook",
  async (bookData, thunkAPI) => {
    try {
      const { data } = await axios.post("/book/upload.php", bookData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateBook = createAsyncThunk(
  "books/updateBook",
  async (bookData, thunkAPI) => {
    try {
      const { data } = await axios.post("/book/index.php", bookData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createCategory = createAsyncThunk(
  "books/createCategory",
  async (name, thunkAPI) => {
    try {
      const { data } = await axios.post("/category/index.php", {
        name,
        object: "book"
      })

      return { data, name }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteCategory = createAsyncThunk(
  "books/deleteCategory",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete("/category/index.php", {
        data: {
          id: id,
          object: "book"
        }
      })

      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateCategory = createAsyncThunk(
  "books/updateCategory",
  async (catData, thunkAPI) => {
    try {
      const { data } = await axios.put("/category/index.php", {
        id: catData.id,
        name: catData.name,
        object: "book",
        lang: catData.lang
      })

      return { error: data.error, id: catData.id, name: catData.name }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  books: [],
  categories: [],
  status: "loading"
}

const bookSlice = createSlice({
  name: "book",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Books
    [getBooks.pending]: (state) => {
      state.books = []
      state.status = "loading"
    },
    [getBooks.fulfilled]: (state, action) => {
      state.books = action.payload
      state.status = "loaded"
    },
    [getBooks.rejected]: (state) => {
      state.books = []
      state.status = "loading"
    },

    // Get Categories
    [getCategories.pending]: (state) => {
      state.categories = []
      state.status = "loading"
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload
      state.status = "loaded"
    },
    [getCategories.rejected]: (state) => {
      state.categories = []
      state.status = "loading"
    },

    // Delete Book
    [deleteBook.pending]: (state) => {
      state.status = "loading"
    },
    [deleteBook.fulfilled]: (state, action) => {
      state.status = "loaded"
      state.books = state.books?.filter(
        (book) => book.id !== action.payload.result
      )

      if (!action.payload.error) {
        toast.success("Книга успешно удалена")
      } else {
        toast.error("Ошибка при удалении книги!")
      }
    },
    [deleteBook.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении книги!")
    },

    // Create Book
    [createBook.pending]: (state) => {
      state.status = "loading"
    },
    [createBook.fulfilled]: (state, action) => {
      state.status = "created"
      if (!action.payload.error) {
        toast.success("Книга успешно создана")
      } else {
        toast.error("Ошибка при создании книги!")
      }
    },
    [createBook.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании книги!")
    },

    // Update Book
    [updateBook.pending]: (state) => {
      state.status = "loading"
    },
    [updateBook.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Книга успешно обновлена")
      } else {
        toast.error("Ошибка при обновлении книги!")
      }
    },
    [updateBook.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при обновлении книги!")
    },

    // Create Category
    [createCategory.pending]: (state) => {
      state.status = "loading"
    },
    [createCategory.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Категория успешно создана")
        state.categories.push({
          id: action.payload.data.id,
          name: action.payload.name,
          book: null
        })
      } else {
        toast.error("Ошибка при создании категории!")
      }
    },
    [createCategory.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании категории!")
    },

    // Delete Category
    [deleteCategory.pending]: (state) => {
      state.status = "loading"
    },
    [deleteCategory.fulfilled]: (state, action) => {
      if (!action.payload.error) {
        toast.success("Категория успешно удалена")
        state.status = "loaded"
        state.categories = state.categories?.filter(
          (book) => book.id !== action.payload.id
        )
      } else {
        toast.error("Ошибка при удалении категории!")
      }
    },
    [deleteCategory.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении книги!")
    },

    // Update Category
    [updateCategory.pending]: (state) => {
      state.status = "loading"
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.status = "loaded"

      if (!action.payload.error) {
        toast.success("Категория успешно изменена")
      } else {
        toast.error("Ошибка при обновлении категории!")
      }
    },
    [updateCategory.rejected]: (state) => {
      toast.error("Ошибка при обновлении категории!")
    }
  }
})

export const bookReducer = bookSlice.reducer
