import React from "react"

const Item = ({ onClickItem, data }) => {
  return (
    <>
      <div className="intro-y" onClick={() => onClickItem(data)}>
        <div className="inbox__item inbox__item--active inline-block sm:block text-slate-600 dark:text-slate-500 bg-slate-100 dark:bg-darkmode-400/70 border-b border-slate-200/60 dark:border-darkmode-400">
          <div className="flex items-center px-5 py-3">
            <div className="w-32 flex-none flex items-center mr-5">
              <div className="w-10 h-10 flex-none image-fit relative">
                <img
                  alt="img"
                  className="rounded-full"
                  src={data?.question.topic.image}
                />
              </div>
              <div className="inbox__item--sender truncate ml-2">
                {data?.question.topic.title}
              </div>
            </div>
            <div className="w-64 sm:w-auto truncate">
              {data?.question.question}
            </div>
            <div className="inbox__item--time whitespace-nowrap ml-auto pl-10">
              {data?.question.date_create}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Item
