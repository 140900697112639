import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { toast } from "react-toastify"

import { createObject, getCategories } from "../../../../redux/slices/object"
import InvalidFeedback from "../../../components/InvalidFeedback"
import uploadImg from "../../../../assets/images/upload.png"
import axios from "../../../../utils/axios"

const ObjectRequest = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categories, status } = useSelector((state) => state.object)
  const [newImage, setNewImage] = useState(null)
  const [regions, setRegions] = useState([])
  const [cities, setCities] = useState([])
  const [currentRegion, setCurrentRegion] = useState(1)
  const [currentCity, setCurrentCity] = useState(1)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const fetchRegions = useCallback(async () => {
    const { data } = await axios.get("/city/regions.php", {
      headers: {
        "Accept-Language": "ru"
      }
    })
    setRegions(data?.result)
  }, [])

  const fetchCities = useCallback(async (currentRegion) => {
    const { data } = await axios.get(`/city/regions.php?id=${currentRegion}`)
    setCities(data?.result?.[0].cities)
  }, [])

  const handleChangeImage = (e) => {
    setNewImage(e.target.files[0])
  }

  const onChangeRegion = (e) => {
    setCurrentRegion(e.target.value)
  }

  const onChangeCity = (e) => {
    setCurrentCity(e.target.value)
  }

  const resetForm = () => {
    reset()
    setCurrentRegion(1)
    setCurrentCity(1)
    setNewImage(null)
  }

  const onSubmit = async (data) => {
    const newObjectData = new FormData()
    newObjectData.append("name", data.name)
    newObjectData.append("address", data.address)
    newObjectData.append("category", data.category)
    newObjectData.append("city", currentCity)
    newObjectData.append("description", data.description)
    newObjectData.append("contact", data.contact)
    newObjectData.append("url", data.url)
    newObjectData.append("image[]", newImage)
    newObjectData.append("active", false)
    newObjectData.append("check", false)

    if (newImage) {
      dispatch(createObject(newObjectData))
    } else {
      toast.error("Загрузите картинку объекта")
    }
  }

  useEffect(() => {
    status === "created" && resetForm()
  }, [navigate, status])

  useEffect(() => {
    fetchRegions()
  }, [fetchRegions])

  useEffect(() => {
    fetchCities(currentRegion)
  }, [fetchCities, currentRegion])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className="col-span-12">
        <div className="intro-y box lg:mt-5">
          <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 className="font-medium text-base mr-auto">
              Заявка на добавление нового объекта
            </h2>
          </div>
          <div className="p-5">
            <div className="flex flex-col-reverse xl:flex-row flex-col">
              <div className="flex-1 xl:mt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-12 gap-x-5">
                    <div className="col-span-12 2xl:col-span-12">
                      <div className="mb-5">
                        <label className="form-label">Название</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Введите название"
                          name="name"
                          {...register("name", { required: true })}
                        />
                        {errors.name && (
                          <InvalidFeedback text={"Введите название"} />
                        )}
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 lg:col-span-6">
                      <div className="mb-5">
                        <label className="form-label">Регион</label>
                        <select
                          className="tom-select w-full"
                          {...register("region", { required: true })}
                          onChange={onChangeRegion}
                        >
                          {regions?.map((cat) => (
                            <option value={cat.id} key={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 lg:col-span-6">
                      <div className="mb-5">
                        <label className="form-label">Город</label>
                        <select
                          className="tom-select w-full"
                          {...register("city", { required: true })}
                          onChange={onChangeCity}
                        >
                          {cities?.map((cat) => (
                            <option value={cat.id} key={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 lg:col-span-6">
                      <div className="mb-5">
                        <label className="form-label">Адрес</label>
                        <input
                          name="address"
                          type="text"
                          {...register("address", { required: true })}
                          className="form-control"
                          placeholder="Введите адрес"
                        />
                        {errors.address && (
                          <InvalidFeedback text={"Введите адрес"} />
                        )}
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 lg:col-span-6">
                      <div className="mb-5">
                        <label className="form-label">Телефон</label>

                        <InputMask
                          mask="+79999999999"
                          name="contact"
                          type="text"
                          {...register("contact", { required: true })}
                          className="form-control"
                          placeholder="Введите телефон"
                        ></InputMask>
                        {errors.contact && (
                          <InvalidFeedback text={"Введите телефон"} />
                        )}
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 lg:col-span-6">
                      <div className="mb-5">
                        <label className="form-label">Ссылка</label>
                        <input
                          name="url"
                          type="text"
                          {...register("url")}
                          className="form-control"
                          placeholder="Введите ссылку"
                        />
                        {errors.url && (
                          <InvalidFeedback text={"Введите ссылку"} />
                        )}
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 lg:col-span-6">
                      <div className="mb-5">
                        <label className="form-label">Категория</label>
                        <select
                          className="tom-select w-full"
                          {...register("category")}
                        >
                          {categories?.map((cat) => (
                            <option value={cat.id} key={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                        {errors.category && (
                          <InvalidFeedback text={"Выберите категорию"} />
                        )}
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="mb-5">
                        <label
                          htmlFor="update-profile-form-5"
                          className="form-label"
                        >
                          Описание
                        </label>
                        <textarea
                          id="update-profile-form-5"
                          className="form-control"
                          placeholder="Введите описание"
                          name="description"
                          {...register("description", { required: true })}
                        ></textarea>
                        {errors.description && (
                          <InvalidFeedback text={"Введите описание"} />
                        )}
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary w-32 mt-2">
                    Сохранить
                  </button>
                </form>
              </div>
              <div className="w-52 mx-auto xl:mr-0 xl:ml-6">
                <div className="border-2 border-dashed shadow-sm border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                  {newImage ? (
                    <div className="w-40 h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                      <img
                        className="rounded-md"
                        alt="image"
                        src={URL.createObjectURL(newImage)}
                      />
                    </div>
                  ) : (
                    <div className="w-32 relative mx-auto">
                      <img
                        className="rounded-md mx-auto"
                        alt="image"
                        src={uploadImg}
                      />
                    </div>
                  )}

                  <div className="mx-auto cursor-pointer relative mt-5">
                    <button type="button" className="btn btn-primary w-full">
                      {newImage ? "Изменить фото" : "Добавить фото"}
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      className="w-full h-full top-0 left-0 absolute opacity-0"
                      onChange={handleChangeImage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ObjectRequest
