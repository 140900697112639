import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Pause, Play, Plus } from "lucide-react"
import AudioPlayer from "react-h5-audio-player"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import InvalidFeedback from "../../../components/InvalidFeedback"
import uploadImg from "../../../../assets/images/upload.png"
import { createAudio, getCategories } from "../../../../redux/slices/audio"

const AudioCreate = () => {
  const { categories, status } = useSelector((state) => state.audio)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const [newImage, setNewImage] = useState(null)
  const [newAudio, setNewAudio] = useState(null)
  const [currentAudio, setCurrentAudio] = useState("")

  const handleChangeImage = (e) => {
    setNewImage(e.target.files[0])
  }

  const handleChangeAudio = (e) => {
    setNewAudio(e.target.files[0])
  }

  const onPlayAudio = (audioLink) => {
    setCurrentAudio(audioLink)
  }

  const onSubmit = async (data) => {
    const newAudioData = new FormData()
    newAudioData.append("title", data.title)
    newAudioData.append("author", data.author)
    newAudioData.append("category", data.category)
    newAudioData.append("description", data.description)

    newImage
      ? newAudioData.append("image", newImage)
      : toast.error("Добавьте картинку")

    newAudio
      ? newAudioData.append("audio", newAudio)
      : toast.error("Добавьте файл")

    if (newImage && newAudio) {
      dispatch(createAudio(newAudioData))
    }
  }

  useEffect(() => {
    status === "created" && navigate("/audio-list")
  }, [navigate, status])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y box lg:mt-5">
            <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400 mt-8">
              <h2 className="font-medium text-base mr-auto">
                Добавить новое аудио
              </h2>
            </div>
            <div className="p-5">
              <div className="flex flex-col-reverse xl:flex-row flex-col">
                <div className="flex-1 mt-6 xl:mt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 gap-x-5">
                      <div className="col-span-12 2xl:col-span-6">
                        <div className="mb-5">
                          <label className="form-label">Название</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Введите название"
                            name="title"
                            {...register("title", { required: true })}
                          />
                          {errors.title && (
                            <InvalidFeedback text={"Введите название"} />
                          )}
                        </div>
                        <div className="mb-5">
                          <label
                            htmlFor="update-profile-form-2"
                            className="form-label"
                          >
                            Категория
                          </label>
                          <select
                            className="tom-select w-full"
                            {...register("category")}
                          >
                            {categories?.map((cat) => (
                              <option value={cat.id} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-span-12 2xl:col-span-6">
                        <div className="mb-5">
                          <label className="form-label">Автор</label>
                          <input
                            name="author"
                            type="text"
                            {...register("author", { required: true })}
                            className="form-control"
                            placeholder="Введите автора"
                          />
                          {errors.author && (
                            <InvalidFeedback text={"Введите имя автора"} />
                          )}
                        </div>
                        <div className="mb-5">
                          <label
                            htmlFor="update-profile-form-4"
                            className="form-label"
                          >
                            Аудиозапись
                          </label>

                          {newAudio ? (
                            <div className="sm:flex">
                              {currentAudio === newAudio ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary hidden sm:flex"
                                  onClick={() => onPlayAudio("")}
                                >
                                  <Pause className="lucide lucide-activity w-4 h-4 mr-2" />
                                  Остановить
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary hidden sm:flex"
                                  onClick={() => {
                                    onPlayAudio(URL.createObjectURL(newAudio))
                                  }}
                                >
                                  <Play className="lucide lucide-activity w-4 h-4 mr-2" />
                                  Прослушать
                                </button>
                              )}

                              <div className="cursor-pointer relative ml-2">
                                <button
                                  type="button"
                                  className="btn btn-secondary w-full"
                                >
                                  Изменить аудио
                                </button>
                                <input
                                  type="file"
                                  accept="audio/*"
                                  className="w-full h-full top-0 left-0 absolute opacity-0"
                                  onChange={handleChangeAudio}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="sm:flex">
                              <div className="cursor-pointer relative">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary w-full"
                                >
                                  <Plus className="lucide lucide-activity w-4 h-4 mr-2" />
                                  Добавить аудио
                                </button>
                                <input
                                  type="file"
                                  accept="audio/*"
                                  className="w-full h-full top-0 left-0 absolute opacity-0"
                                  onChange={handleChangeAudio}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="mb-5">
                          <label className="form-label">Описание</label>
                          <textarea
                            className="form-control"
                            placeholder="Введите описание"
                            name="description"
                            {...register("description")}
                          ></textarea>
                          {errors.description && (
                            <InvalidFeedback text={"Введите описание"} />
                          )}
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-32 mt-2">
                      Сохранить
                    </button>
                  </form>
                </div>
                <div className="w-52 mx-auto xl:mr-0 xl:ml-6">
                  <div className="border-2 border-dashed shadow-sm border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                    {newImage ? (
                      <div className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                        <img
                          className="rounded-md"
                          alt="image"
                          src={URL.createObjectURL(newImage)}
                        />
                      </div>
                    ) : (
                      <div className="w-32 relative mx-auto">
                        <img
                          className="rounded-md mx-auto"
                          alt="image"
                          src={uploadImg}
                        />
                      </div>
                    )}

                    <div className="mx-auto cursor-pointer relative mt-5">
                      <button type="button" className="btn btn-primary w-full">
                        {newImage ? "Изменить фото" : "Добавить фото"}
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        className="w-full h-full top-0 left-0 absolute opacity-0"
                        onChange={handleChangeImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {currentAudio && (
        <div className="intro-y box mt-5">
          <AudioPlayer
            autoPlay
            autoPlayAfterSrcChange
            src={currentAudio}
            onEnded={() => setCurrentAudio("")}
          />
        </div>
      )}
    </>
  )
}

export default AudioCreate
