import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import "react-h5-audio-player/lib/styles.css"
import { useForm } from "react-hook-form"

import { createCategory, getCategories } from "../../../../redux/slices/audio"
import InvalidFeedback from "../../../components/InvalidFeedback"
import DeleteModal from "./DeleteModal"
import EditModal from "./EditModal"
import Category from "./Category"

const AudioCategories = () => {
  const dispatch = useDispatch()
  const { categories } = useSelector((state) => state.audio)
  const [removeCategory, setRemoveCategory] = useState(null)
  const [editCategory, setEditCategory] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = (data) => {
    dispatch(createCategory(data.title))
  }

  const onClickShowDeleteModal = (category) => {
    setRemoveCategory(category)
  }

  const onClickShowEditModal = (category) => {
    setEditCategory(category)
  }

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className="intro-y flex items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Список категорий</h2>
      </div>
      <div className="grid grid-cols-12 gap-6 mt-5">
        {categories?.length ? (
          <div className="intro-y col-span-12 lg:col-span-8 overflow-auto 2xl:overflow-visible">
            <table className="table table-report -mt-2">
              <tbody>
                {categories?.map((category, index) => (
                  <Category
                    key={index}
                    category={category}
                    onClickShowDeleteModal={onClickShowDeleteModal}
                    onClickShowEditModal={onClickShowEditModal}
                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="intro-y col-span-12 lg:col-span-8 overflow-auto 2xl:overflow-visible">
            <div className="alert alert-primary show mb-2 mt-2">
              <div>Категорий пока нет...</div>
            </div>
          </div>
        )}

        <div className="intro-y col-span-12 lg:col-span-4">
          <div className="intro-y box">
            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
              <h2 className="font-medium text-base mr-auto">
                Добавить категорию
              </h2>
            </div>
            <div id="vertical-form" className="p-5">
              <div className="preview">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label className="form-label">Заголовок</label>
                    <input
                      {...register("title", { required: true })}
                      type="text"
                      className="form-control"
                      placeholder="Введите название категории"
                    />
                    {errors.title && (
                      <InvalidFeedback text={"Введите название"} />
                    )}
                  </div>

                  <button className="btn btn-primary mt-5" type="submit">
                    Добавить
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {removeCategory && (
        <DeleteModal
          onClickShowDeleteModal={onClickShowDeleteModal}
          removeCategory={removeCategory}
        />
      )}

      {editCategory && (
        <EditModal
          onClickShowEditModal={onClickShowEditModal}
          editCategory={editCategory}
        />
      )}
    </>
  )
}

export default AudioCategories
