import React, { useEffect, useState } from "react"
import { ChevronDown } from "lucide-react"
import { useSelector } from "react-redux"
import { Link, NavLink, useLocation } from "react-router-dom"

import logo from "../../../assets/images/logo/logo-no-bg.svg"
import { publicMenu, privateMenu } from "../../../utils/navMenu"

const Sidebar = () => {
  const location = useLocation()
  const [activeItem, setActiveItem] = useState("")
  const { user } = useSelector((state) => state.auth)

  const renderSidebarMenu = () => {
    if (
      user?.role?.type === "administrator" ||
      user?.role?.type === "superadmin"
    ) {
      return privateMenu.map((item, i) => (
        <li key={i}>
          {item?.sub ? (
            <>
              <button
                className={`side-menu w-full ${
                  activeItem.toString().includes(item.type)
                    ? "side-menu--active"
                    : ""
                }`}
                onClick={() => setActiveItem(item.type)}
              >
                <div className="side-menu__icon">{item.icon}</div>
                <div className="side-menu__title">
                  {item.title}
                  <div
                    className={`side-menu__sub-icon ${
                      activeItem.toString().includes(item.type)
                        ? "transform rotate-180"
                        : ""
                    }`}
                  >
                    <ChevronDown />
                  </div>
                </div>
              </button>
              <ul
                className="side-menu__sub-open"
                style={{
                  display: activeItem.toString().includes(item.type)
                    ? "block"
                    : "none"
                }}
              >
                {item.sub.map((item, k) => (
                  <li key={k}>
                    <NavLink
                      to={item.path}
                      className={({ isActive }) =>
                        `side-menu ${isActive ? "side-menu--active" : ""}`
                      }
                    >
                      <div className="side-menu__icon">{item.icon}</div>
                      <div className="side-menu__title">{item.title}</div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                `side-menu ${isActive ? "side-menu--active" : ""}`
              }
            >
              <div className="side-menu__icon">{item.icon}</div>
              <div className="side-menu__title">{item.title}</div>
            </NavLink>
          )}
        </li>
      ))
    } else {
      return publicMenu.map((item, i) => (
        <li key={i}>
          {item?.sub ? (
            <>
              <button
                className={`side-menu w-full ${
                  activeItem.toString().includes(item.type)
                    ? "side-menu--active"
                    : ""
                }`}
                onClick={() => setActiveItem(item.type)}
              >
                <div className="side-menu__icon">{item.icon}</div>
                <div className="side-menu__title">
                  {item.title}
                  <div
                    className={`side-menu__sub-icon ${
                      activeItem.toString().includes(item.type)
                        ? "transform rotate-180"
                        : ""
                    }`}
                  >
                    <ChevronDown />
                  </div>
                </div>
              </button>
              <ul
                className="side-menu__sub-open"
                style={{
                  display: activeItem.toString().includes(item.type)
                    ? "block"
                    : "none"
                }}
              >
                {item.sub.map((item, k) => (
                  <li key={k}>
                    <NavLink
                      to={item.path}
                      className={({ isActive }) =>
                        `side-menu ${isActive ? "side-menu--active" : ""}`
                      }
                    >
                      <div className="side-menu__icon">{item.icon}</div>
                      <div className="side-menu__title">{item.title}</div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                `side-menu ${isActive ? "side-menu--active" : ""}`
              }
            >
              <div className="side-menu__icon">{item.icon}</div>
              <div className="side-menu__title">{item.title}</div>
            </NavLink>
          )}
        </li>
      ))
    }
  }

  useEffect(() => {
    setActiveItem(location.pathname)
  }, [])

  return (
    <>
      <nav className="side-nav">
        <Link to="/" className="intro-x flex items-center pl-5 pt-4 mt-3">
          <img alt="ДумРБ" className="w-12" src={logo} />
          <span className="hidden xl:block text-white text-lg ml-3 font-semibold">
            ДУМ РБ
          </span>
        </Link>
        <div className="side-nav__devider my-6"></div>
        <ul>{renderSidebarMenu()}</ul>
      </nav>
    </>
  )
}

export default Sidebar
