import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Plus } from "lucide-react"

import { getCategories } from "../../../../redux/slices/video"
import axios from "../../../../utils/axios"
import Video from "./Video"

const VideoList = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const [videos, setVideos] = useState(null)

  const fetchVideo = useCallback(async () => {
    const { data } = await axios.get(`/video/index.php?category=${params.id}`)
    setVideos(data.result)
  }, [params.id])

  useEffect(() => {
    fetchVideo()
  }, [fetchVideo])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch, params])

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y col-span-12 flex flex-wrap xl:flex-nowrap items-center mt-2">
            <h2 className="text-lg font-medium mt-2">Список видео</h2>
            <div className="hidden xl:block mx-auto text-slate-500">
              Всего видео: {videos?.length}
            </div>
            <div className="w-full xl:w-auto flex flex-wrap xl:flex-nowrap items-center gap-y-3 mt-3 xl:mt-0">
              <Link
                to="/video-create"
                className="btn btn-primary shadow-md mr-2"
              >
                <Plus className="w-4 h-4 mr-2"></Plus> Добавить видео
              </Link>
            </div>
          </div>
        </div>

        {videos?.length ? (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <table className="table table-report -mt-2">
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Название видео</th>
                  <th className="text-center whitespace-nowrap">Изменить</th>
                </tr>
              </thead>
              <tbody>
                {videos?.map((video, index) => (
                  <Video key={index} video={video} />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <div className="alert alert-primary show mb-2 mt-2">
              <div>Видеороликов пока нет...</div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default VideoList
