import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Plus, Search } from 'lucide-react';
import AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import 'react-h5-audio-player/lib/styles.css';

import { getAudios, getCategories } from '../../../../redux/slices/audio';
import Audio from './Audio';
import DeleteModal from './DeleteModal';

const AudioList = () => {
  const dispatch = useDispatch();
  const { audios, pages } = useSelector((state) => state.audio);
  const [searchValue, setSearchValue] = useState('');
  const [currentAudio, setCurrentAudio] = useState('');
  const [removeAudio, setRemoveAudio] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const onChangeAudio = (audioLink) => {
    setCurrentAudio(audioLink);
  };

  const onClickShowDeleteModal = (audio) => {
    setRemoveAudio(audio);
  };

  useEffect(() => {
    dispatch(getAudios(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(getAudios());
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <h2 className="text-lg font-medium mr-auto mt-2">Список аудио</h2>
        </div>
        <div className="intro-y col-span-12 flex flex-wrap xl:flex-nowrap items-center mt-2">
          <div className="flex w-full sm:w-auto">
            <div className="w-52 relative text-slate-500">
              <input
                type="text"
                className="form-control w-52 box pr-10"
                placeholder="Найти аудио..."
                value={searchValue}
                onChange={onChangeSearchValue}
              />
              <Search className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"></Search>
            </div>
          </div>
          <div className="hidden xl:block mx-auto text-slate-500">
            Всего аудио: {audios?.length}
          </div>
          <div className="w-full xl:w-auto flex flex-wrap xl:flex-nowrap items-center gap-y-3 mt-3 xl:mt-0">
            <Link to="/audio-create" className="btn btn-primary shadow-md mr-2">
              <Plus className="w-4 h-4 mr-2"></Plus> Добавить аудио
            </Link>
          </div>
        </div>

        {audios?.length ? (
          <>
            <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
              <table className="table table-report -mt-2">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap">Название аудио</th>
                    <th className="text-center whitespace-nowrap">Аудио</th>
                    <th className="text-center whitespace-nowrap">Изменить</th>
                  </tr>
                </thead>
                <tbody>
                  {audios
                    ?.filter((audio) => {
                      return audio.title.toLowerCase().includes(searchValue.toLowerCase());
                    })
                    ?.map((audio, index) => (
                      <Audio
                        key={index}
                        audio={audio}
                        onChangeAudio={onChangeAudio}
                        currentAudio={currentAudio}
                        onClickShowDeleteModal={onClickShowDeleteModal}
                      />
                    ))}
                </tbody>
              </table>
            </div>

            {pages > 1 && (
              <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                <nav class="w-full sm:w-auto sm:mr-auto">
                  <ul class="pagination">
                    <li class="page-item">
                      <button
                        class="page-link"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage((prev) => prev - 1)}>
                        <ChevronLeft class="w-4 h-4" />
                      </button>
                    </li>
                    {[...Array(pages)]?.map((page, i) => (
                      <li class={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                        <button class="page-link" onClick={() => setCurrentPage(i + 1)}>
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li class="page-item">
                      <button
                        class="page-link"
                        disabled={currentPage === pages}
                        onClick={() => setCurrentPage((prev) => prev + 1)}>
                        <ChevronRight class="w-4 h-4" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </>
        ) : (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <div className="alert alert-primary show mb-2 mt-2">
              <div>Аудиозаписей пока нет...</div>
            </div>
          </div>
        )}
      </div>

      {currentAudio && (
        <AudioPlayer
          autoPlay
          autoPlayAfterSrcChange
          src={currentAudio}
          onEnded={() => setCurrentAudio('')}
        />
      )}

      {removeAudio && (
        <DeleteModal onClickShowDeleteModal={onClickShowDeleteModal} removeAudio={removeAudio} />
      )}
    </>
  );
};

export default AudioList;
