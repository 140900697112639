import React from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

const RoleAccess = ({ roles = [] }) => {
  const { user } = useSelector((state) => state.auth)

  return !roles.length || roles.includes(user?.role.type) ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  )
}

export default RoleAccess
