import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Plus } from "lucide-react"

import { createVideo } from "../../../../../redux/slices/video"
import axios from "../../../../../utils/axios"
import VideoItem from "./VideoItem"

const VideoCreateSelect = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [playlist, setPlaylist] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedVideos, setSelectedVideos] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(1)

  const selectVideo = (video) => {
    setSelectedVideos((selectedVideos) => [...selectedVideos, video])
  }

  const removeVideo = (id) => {
    const newList = selectedVideos?.filter((video) => video.id !== id)
    setSelectedVideos(newList)
  }

  const onClickAddVideos = () => {
    const newVideos = selectedVideos?.map((item) => {
      delete item.id
      delete item.like
      return { ...item, category: selectedCategory }
    })
    dispatch(createVideo(newVideos))
  }

  const onChangeCategory = (e) => {
    setSelectedCategory(parseInt(e.target.value))
  }

  const fetchCategories = useCallback(async () => {
    const { data } = await axios.get("/category/index.php?category=video")
    if (!data?.error) {
      setCategories(data?.result)
    }
  }, [])

  const fetchVideo = useCallback(async () => {
    const { data } = await axios.get(
      `/video/listVideo.php?playlist=${params.id}`
    )

    if (!data?.error) {
      setPlaylist(data?.result)
    }
  }, [params.id])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  useEffect(() => {
    fetchVideo()
  }, [fetchVideo])

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        {playlist?.length ? (
          <>
            <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
              <div className="intro-y col-span-12 flex flex-wrap xl:flex-nowrap items-center mt-2">
                <h2 className="text-lg font-medium mt-2">
                  Выберите видео из списка
                </h2>

                <div className="xl:block mx-auto mr-5">
                  <label className="form-label">Категория</label>
                  <select
                    className="tom-select ml-2"
                    onChange={onChangeCategory}
                  >
                    {categories?.map((cat) => (
                      <option value={cat.id} key={cat.id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-full xl:w-auto flex flex-wrap xl:flex-nowrap items-center gap-y-3 mt-3 xl:mt-0">
                  <button
                    className="btn btn-primary shadow-md mr-2"
                    onClick={onClickAddVideos}
                  >
                    <Plus className="w-4 h-4 mr-2"></Plus> Добавить выбранные
                  </button>
                </div>
              </div>
            </div>

            <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
              <table className="table table-report -mt-2">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap">Название видео</th>
                    <th className="whitespace-nowrap">Добавить</th>
                  </tr>
                </thead>
                <tbody>
                  {playlist?.map((video, i) => (
                    <VideoItem
                      video={video}
                      key={i}
                      selectVideo={selectVideo}
                      removeVideo={removeVideo}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <h2 className="text-lg font-medium mt-2">
              Выберите видео из списка
            </h2>
            <div className="alert alert-primary show mb-2 mt-5">
              <div className="flex items-center">
                <div className="font-medium text-lg">
                  Нет видео в плейлисте.
                </div>
              </div>
              <div className="mt-3">Обратитесь в техническую поддержку!</div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default VideoCreateSelect
