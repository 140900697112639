import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Search } from "lucide-react"

import { getMessages } from "../../../redux/slices/message"
import MessageItem from "./MessageItem"
import Modal from "./Modal"

const FeedbackList = () => {
  const dispatch = useDispatch()
  const { messages } = useSelector((state) => state.message)
  const [searchValue, setSearchValue] = useState("")
  const [showModal, setShowModal] = useState(null)

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value)
  }

  const onClickItem = (data) => {
    setShowModal(data)
  }

  useEffect(() => {
    dispatch(getMessages())
  }, [dispatch])

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-8">
        <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y flex flex-col-reverse sm:flex-row items-center">
            <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
              <h2 className="text-lg font-medium mr-auto mt-2">
                Обратная связь
              </h2>
            </div>
            <div className="w-full sm:w-auto relative ml-auto mt-3 sm:mt-0">
              <Search className="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-slate-500" />
              <input
                type="text"
                className="form-control w-full sm:w-64 box px-10"
                placeholder="Поиск по тексту"
                value={searchValue}
                onChange={onChangeSearchValue}
              />
            </div>
          </div>

          <div className="intro-y inbox box mt-5">
            <div className="p-5 flex flex-col-reverse sm:flex-row text-slate-500 border-b border-slate-200/60">
              <div className="flex items-center sm:mr-auto">
                <div className="">Всего сообщений: {messages?.length}</div>
              </div>
            </div>
            <div className="overflow-x-auto sm:overflow-x-visible">
              {messages ? (
                messages
                  ?.filter((message) => {
                    return (message.title + message.description)
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  })
                  .map((message) => (
                    <MessageItem
                      message={message}
                      key={message?.id}
                      onClickItem={onClickItem}
                    />
                  ))
              ) : (
                <p className="p-5">Вопросов пока нет...</p>
              )}
            </div>
            <div className="p-2 flex flex-col sm:flex-row items-center text-center sm:text-left text-slate-500"></div>
          </div>
        </div>
      </div>

      {showModal && <Modal showModal={showModal} onClickItem={onClickItem} />}
    </>
  )
}

export default FeedbackList
