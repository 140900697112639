import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { ChevronDown, Eye } from "lucide-react"
import { useDispatch, useSelector } from "react-redux"

import { getAbout, updateMainInfo } from "../../../../redux/slices/about"
import InvalidFeedback from "../../../components/InvalidFeedback"
import axios from "../../../../utils/axios"

const GeneralTab = () => {
  const dispatch = useDispatch()
  const { about } = useSelector((state) => state.about)
  const [newImageLink, setNewImageLink] = useState("")
  const [newImage, setNewImage] = useState(null)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const handleChangeImage = async (e) => {
    setNewImage(e.target.files[0])

    const newImageData = new FormData()
    newImageData.append("image[]", e.target.files[0])
    try {
      const { data } = await axios.post("/news/upload.php", newImageData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      if (data.length > 0) {
        setNewImageLink(data?.[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (data) => {
    const aboutData = {
      title: data?.title,
      description: data?.description,
      address: data?.address,
      city: data?.city?.id,
      email: data?.mail,
      phone: data?.phone,
      image: newImageLink ? newImageLink : data?.image,
      version: "0.0.1",
      date: "2022-01-19",
      lang: "ru"
    }

    dispatch(updateMainInfo(aboutData))
  }

  useEffect(() => {
    reset(about)
  }, [about])

  useEffect(() => {
    dispatch(getAbout())
  }, [dispatch])

  return (
    <>
      <div className="tab-pane p-5 active">
        <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
          <div className="font-medium flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
            <ChevronDown className="w-4 h-4 mr-2" />
            Основная информация
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-x-5 mt-2">
              <div className="col-span-12 2xl:col-span-6">
                <div className="mt-5">
                  <label className="form-label">Название</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите название"
                    name="title"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <InvalidFeedback text={"Введите название"} />
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-6">
                <div className="mt-5">
                  <label className="form-label">Адрес</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите адрес"
                    name="address"
                    {...register("address", { required: true })}
                  />
                  {errors.address && <InvalidFeedback text={"Введите адрес"} />}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-4">
                <div className="mt-5">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Введите Email"
                    name="mail"
                    {...register("mail", { required: true })}
                  />
                  {errors.mail && <InvalidFeedback text={"Введите Email"} />}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3">
                <div className="mt-5">
                  <label className="form-label">Изображение (баннер)</label>
                  <div className="flex">
                    <a
                      href={about?.image}
                      target="_blank"
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      <Eye />
                    </a>
                    <div className="cursor-pointer relative ml-3">
                      <button
                        type="button"
                        className={`btn ${
                          newImage ? "btn-outline-secondary" : "btn-secondary"
                        }  w-full`}
                      >
                        Изменить изображение
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        className="w-full h-full top-0 left-0 absolute opacity-0"
                        onChange={handleChangeImage}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-12">
                <div className="mt-5">
                  <label className="form-label">Описание</label>
                  <textarea
                    id="update-profile-form-5"
                    className="form-control"
                    placeholder="Введите описание"
                    name="description"
                    rows="3"
                    {...register("description", { required: true })}
                  ></textarea>
                  {errors.description && (
                    <InvalidFeedback text={"Введите описание"} />
                  )}
                </div>
              </div>

              <button type="submit" className="btn btn-primary w-32 mt-5">
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default GeneralTab
