import React from "react"

const InvalidFeedback = ({ text }) => {
  return (
    <>
      <div className="pristine-error text-danger mt-2">{text}</div>
    </>
  )
}

export default InvalidFeedback
