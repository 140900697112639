import React from "react"
import CreateTab from "./createTab"
import AnsweredTab from "./answeredTab"
import UnansweredTab from "./unansweredTab"
import CreateCategoryTab from "./createCategoryTab"

const Content = ({
  answered,
  unanswered,
  activeTab,
  onChangeTab,
  showView,
  onClickItem
}) => {
  return (
    <>
      <div className="col-span-12 lg:col-span-9 2xl:col-span-9">
        {activeTab === 2 ? (
          <UnansweredTab
            unanswered={unanswered}
            onChangeTab={onChangeTab}
            showView={showView}
            onClickItem={onClickItem}
          />
        ) : activeTab === 3 ? (
          <CreateTab onChangeTab={onChangeTab} />
        ) : activeTab === 4 ? (
          <CreateCategoryTab />
        ) : (
          <AnsweredTab
            answered={answered}
            showView={showView}
            onChangeTab={onChangeTab}
            onClickItem={onClickItem}
          />
        )}
      </div>
    </>
  )
}

export default Content
