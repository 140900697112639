import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChevronLeft, ChevronRight } from "lucide-react"
import Carousel from "react-tiny-slider"

import { getMessages } from "../../../../redux/slices/message"

const CarouselMessages = () => {
  const carousel = useRef(null)
  const dispatch = useDispatch()
  const goNextSlide = () => carousel.current.goTo("next")
  const goPrevSlide = () => carousel.current.goTo("prev")

  const { messages } = useSelector((state) => state.message)

  const truncate = (str, maxlength) => {
    if (str.length > maxlength) {
      str = str.substring(0, maxlength - 3)
      return str + "..."
    }
    return str
  }

  const capitalize = (s) => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  useEffect(() => {
    dispatch(getMessages())
  }, [dispatch])

  return (
    <>
      <div className="intro-x flex items-center h-10">
        <h2 className="text-lg font-medium truncate mr-auto">Сообщения</h2>
        <button
          onClick={() => goPrevSlide()}
          className="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
        >
          <ChevronLeft className="w-4 h-4"></ChevronLeft>
        </button>
        <button
          onClick={() => goNextSlide()}
          className="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
        >
          <ChevronRight className="w-4 h-4"></ChevronRight>
        </button>
      </div>
      <div className="mt-5 intro-x">
        <div className="box zoom-in">
          <Carousel
            swipeAngle={false}
            items={1}
            mouseDrag
            controls={false}
            nav={false}
            ref={carousel}
            autoplay
            className="tiny-slider"
          >
            {messages?.map((message, i) => (
              <div className="p-5" key={i}>
                <div className="text-base font-medium truncate">
                  {capitalize(message?.title)}
                </div>
                <div className="text-slate-400 mt-1">
                  {message?.date_create}
                </div>
                <div className="text-slate-500 text-justify mt-1">
                  {capitalize(truncate(message?.description, 80))}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  )
}

export default CarouselMessages
