import React from "react"
import { Edit } from "lucide-react"
import { Link } from "react-router-dom"

const Video = ({ video }) => {
  return (
    <>
      <tr className="intro-x">
        <td className="!py-3.5">
          <div className="flex items-center">
            <div className="w-9 h-9 image-fit zoom-in">
              <img
                alt="img"
                className="rounded-lg border-white shadow-md tooltip"
                src={video?.image?.default?.url}
              />
            </div>
            <div className="ml-4 w-176 truncate">
              <Link
                to={`/video/${video?.id}`}
                className="font-medium whitespace-nowrap"
              >
                {video?.title}
              </Link>
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {video?.description}
              </div>
            </div>
          </div>
        </td>

        <td className="table-report__action">
          <div className="flex justify-center items-center">
            <Link to={`/video/${video?.id}`} className="flex items-center mr-3">
              <Edit className="w-4 h-4 mr-1"></Edit> Изменить
            </Link>
          </div>
        </td>
      </tr>
    </>
  )
}

export default Video
