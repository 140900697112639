import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import AudioPlayer from "react-h5-audio-player"
import { useParams } from "react-router-dom"
import { Pause, Play } from "lucide-react"
import { useForm } from "react-hook-form"
import Select from "react-select"

import axios from "../../../../utils/axios"
import { languages } from "../../../../utils/languages"
import InvalidFeedback from "../../../components/InvalidFeedback"
import { getCategories, updateAudio } from "../../../../redux/slices/audio"

const AudioView = () => {
  const [audioData, setAudioData] = useState(null)
  const { categories } = useSelector((state) => state.audio)
  const params = useParams()
  const dispatch = useDispatch()
  const [currentLang, setCurrentLang] = useState("ru")

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const fetchAudio = useCallback(
    async (lang) => {
      const { data } = await axios.get(`/audio/index.php?id=${params.id}`, {
        headers: { "Accept-Language": lang }
      })

      setAudioData({
        id: data.result[0]?.id,
        title: data.result[0]?.title,
        author: data.result[0]?.author,
        description: data.result[0]?.description,
        url: data.result[0]?.url,
        image: data.result[0]?.image,
        category: data.result[0]?.category?.id
      })
    },
    [params.id]
  )

  const [newImage, setNewImage] = useState(null)
  const [newAudio, setNewAudio] = useState(null)
  const [currentAudio, setCurrentAudio] = useState("")

  const handleChangeImage = (e) => {
    setNewImage(e.target.files[0])
  }

  const handleChangeAudio = (e) => {
    setNewAudio(e.target.files[0])
  }

  const onPlayAudio = (audioLink) => {
    setCurrentAudio(audioLink)
  }

  const onChangeLang = (e) => {
    setCurrentLang(e.value)
  }

  const onSubmit = async (data) => {
    const newAudioData = new FormData()
    newAudioData.append("id", data.id)
    newAudioData.append("title", data.title)
    newAudioData.append("author", data.author)
    newAudioData.append("category", data.category)
    newAudioData.append("description", data.description)
    newAudioData.append("lang", currentLang)

    newImage
      ? newAudioData.append("image", newImage)
      : newAudioData.append("image_url", data.image)

    newAudio
      ? newAudioData.append("audio", newAudio)
      : newAudioData.append("audio_url", data.url)

    dispatch(updateAudio(newAudioData))
  }

  useEffect(() => {
    reset(audioData)
  }, [audioData, currentLang])

  useEffect(() => {
    fetchAudio(currentLang)
    reset(audioData)
  }, [fetchAudio, currentLang])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y box lg:mt-5">
            <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400 mt-8">
              <h2 className="font-medium text-base mr-auto">Изменить аудио</h2>
              <Select
                placeholder="Выберите язык"
                className="basic-single-select"
                value={languages?.filter((obj) => obj.value === currentLang)}
                options={languages}
                onChange={onChangeLang}
              />
            </div>
            <div className="p-5">
              <div className="flex flex-col-reverse xl:flex-row flex-col">
                <div className="flex-1 mt-6 xl:mt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 gap-x-5">
                      <div className="col-span-12 2xl:col-span-6">
                        <div className="mb-5">
                          <label className="form-label">Название</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Введите название"
                            name="title"
                            {...register("title", { required: true })}
                          />
                          {errors.title && (
                            <InvalidFeedback text={"Введите название"} />
                          )}
                        </div>
                        <div className="mb-5">
                          <label
                            htmlFor="update-profile-form-2"
                            className="form-label"
                          >
                            Категория
                          </label>
                          <select
                            className="tom-select w-full"
                            {...register("category", { required: true })}
                          >
                            {categories?.map((cat) => (
                              <option value={cat.id} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                          {errors.category && (
                            <InvalidFeedback text={"Выберите категорию"} />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12 2xl:col-span-6">
                        <div className="mb-5">
                          <label className="form-label">Автор</label>
                          <input
                            name="author"
                            type="text"
                            {...register("author", { required: true })}
                            className="form-control"
                            placeholder="Введите автора"
                          />
                          {errors.author && (
                            <InvalidFeedback text={"Введите имя автора"} />
                          )}
                        </div>
                        <div className="mb-5">
                          <label
                            htmlFor="update-profile-form-4"
                            className="form-label"
                          >
                            Аудиозапись
                          </label>

                          <div className="sm:flex">
                            {currentAudio === audioData?.url ? (
                              <button
                                type="button"
                                className="btn btn-outline-secondary hidden sm:flex"
                                onClick={() => onPlayAudio("")}
                              >
                                <Pause className="lucide lucide-activity w-4 h-4 mr-2" />
                                Остановить
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-outline-secondary hidden sm:flex"
                                onClick={() => {
                                  newAudio
                                    ? onPlayAudio(URL.createObjectURL(newAudio))
                                    : onPlayAudio(audioData?.url)
                                }}
                              >
                                <Play className="lucide lucide-activity w-4 h-4 mr-2" />
                                Прослушать
                              </button>
                            )}

                            <div className="cursor-pointer relative ml-2">
                              <button
                                type="button"
                                className="btn btn-secondary w-full"
                              >
                                Изменить аудио
                              </button>
                              <input
                                type="file"
                                accept="audio/*"
                                className="w-full h-full top-0 left-0 absolute opacity-0"
                                onChange={handleChangeAudio}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="mb-5">
                          <label className="form-label">Описание</label>
                          <textarea
                            className="form-control"
                            placeholder="Введите описание"
                            name="description"
                            {...register("description")}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-32 mt-5">
                      Сохранить
                    </button>
                  </form>
                </div>
                <div className="w-52 mx-auto xl:mr-0 xl:ml-6">
                  <div className="border-2 border-dashed shadow-sm border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                    <div className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                      {newImage ? (
                        <img
                          className="rounded-md"
                          alt="image"
                          src={URL.createObjectURL(newImage)}
                        />
                      ) : (
                        <img
                          className="rounded-md"
                          alt="image"
                          src={audioData?.image}
                        />
                      )}
                    </div>
                    <div className="mx-auto cursor-pointer relative mt-5">
                      <button type="button" className="btn btn-primary w-full">
                        Изменить фото
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        className="w-full h-full top-0 left-0 absolute opacity-0"
                        onChange={handleChangeImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {currentAudio && (
        <div className="intro-y box mt-5">
          <AudioPlayer
            autoPlay
            autoPlayAfterSrcChange
            src={currentAudio}
            onEnded={() => setCurrentAudio("")}
          />
        </div>
      )}
    </>
  )
}

export default AudioView
