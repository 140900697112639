import React, { useCallback, useEffect } from "react"
import { Book, Building2, Mic, Music4 } from "lucide-react"
import { useState } from "react"
import axios from "../../../../utils/axios"

const Stats = () => {
  const [stats, setStats] = useState([])

  const fetchStats = useCallback(async () => {
    const { data } = await axios.get("/about/stat.php", {
      headers: {
        "Accept-Language": "ru"
      }
    })
    if (!data?.error) {
      setStats(data?.result)
      console.log(data?.result)
    }
  }, [])

  useEffect(() => {
    fetchStats()
  }, [])

  return (
    <>
      <div className="intro-y flex items-center h-10">
        <h2 className="text-lg font-medium truncate mr-5">Статистика</h2>
      </div>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div className="report-box zoom-in">
            <div className="box p-5">
              <div className="flex">
                <Building2 className="report-box__icon text-primary"></Building2>
              </div>
              <div className="text-3xl font-medium leading-8 mt-6">
                {stats?.[3]?.object}
              </div>
              <div className="text-base text-slate-500 mt-1">Объектов</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div className="report-box zoom-in">
            <div className="box p-5">
              <div className="flex">
                <Book className="report-box__icon text-pending"></Book>
              </div>
              <div className="text-3xl font-medium leading-8 mt-6">
                {stats?.[1]?.book}
              </div>
              <div className="text-base text-slate-500 mt-1">Книг</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div className="report-box zoom-in">
            <div className="box p-5">
              <div className="flex">
                <Music4 className="report-box__icon text-warning"></Music4>
              </div>
              <div className="text-3xl font-medium leading-8 mt-6">
                {stats?.[0]?.audio}
              </div>
              <div className="text-base text-slate-500 mt-1">Аудио</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div className="report-box zoom-in">
            <div className="box p-5">
              <div className="flex">
                <Mic className="report-box__icon text-success"></Mic>
              </div>
              <div className="text-3xl font-medium leading-8 mt-6">
                {stats?.[2]?.reader}
              </div>
              <div className="text-base text-slate-500 mt-1">Чтецов</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Stats
