import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChevronDown } from "lucide-react"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import Select from "react-select"

import InvalidFeedback from "../../../../components/InvalidFeedback"
import axios from "../../../../../utils/axios"
import { changeStatus, updateObject } from "../../../../../redux/slices/object"
import uploadImg from "../../../../../assets/images/upload.png"
import { languages } from "../../../../../utils/languages"

const GeneralTab = ({ objectData, currentLang, onChangeLang }) => {
  const dispatch = useDispatch()
  const { categories } = useSelector((state) => state.object)
  const [newImage, setNewImage] = useState(null)
  const [currentImage, setCurrentImage] = useState(null)
  const [regions, setRegions] = useState([])
  const [cities, setCities] = useState([])
  const [currentRegion, setCurrentRegion] = useState(1)
  const [currentCity, setCurrentCity] = useState(1)
  const [isActive, setIsActive] = useState(false)
  const [isChecked, setIsChecked] = useState(true)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const handleChangeImage = async (e) => {
    setNewImage(e.target.files[0])
    const formData = new FormData()
    formData.append("image[]", e.target.files[0])
    try {
      const { data } = await axios.post("/object/uploadImage.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      if (!data?.error) {
        setCurrentImage(data?.result?.[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeRegion = (e) => {
    setCurrentRegion(e.target.value)
  }

  const onChangeCity = (e) => {
    setCurrentCity(e.target.value)
  }

  const onChangeIsActive = (e) => {
    setIsActive(e.target.value)
  }

  const onChangeIsChecked = (e) => {
    setIsChecked(e.target.value)
  }

  const fetchRegions = useCallback(async () => {
    const { data } = await axios.get("/city/regions.php", {
      headers: {
        "Accept-Language": "ru"
      }
    })
    setRegions(data?.result)
  }, [])

  const fetchCities = useCallback(async (currentRegion) => {
    const { data } = await axios.get(`/city/regions.php?id=${currentRegion}`)
    setCities(data?.result?.[0]?.cities)
  }, [])

  const onSubmit = async (data) => {
    const newObjectData = {
      id: data?.id,
      name: data?.name,
      category: data?.category,
      photo: data?.photo,
      sheldule: data?.sheldule,
      contact: [data?.contact],
      url: data?.url,
      description: data?.description,
      city: currentCity,
      address: data?.address,
      main_image: currentImage ? currentImage : objectData?.main_image,
      lang: currentLang
    }
    dispatch(updateObject(newObjectData))
    dispatch(
      changeStatus({
        id: data?.id,
        active: isActive,
        check: isChecked
      })
    )
  }

  useEffect(() => {
    fetchRegions()
  }, [fetchRegions])

  useEffect(() => {
    fetchCities(currentRegion)
  }, [fetchCities, currentRegion])

  useEffect(() => {
    reset(objectData)
  }, [currentLang])

  useEffect(() => {
    reset(objectData)
    setCurrentRegion(objectData?.region)
    setCurrentCity(objectData?.city)
    setIsActive(objectData?.active)
  }, [objectData])

  return (
    <>
      <div className="tab-pane p-5 active">
        <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
          <div className="font-medium flex items-center justify-content-between border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
            <div className="flex items-center">
              <ChevronDown className="w-4 h-4 mr-2" />
              Основная информация
            </div>
            <Select
              placeholder="Выберите язык"
              className="basic-single-select"
              value={languages?.filter((obj) => obj.value === currentLang)}
              options={languages}
              onChange={onChangeLang}
            />
          </div>
          <div className="flex flex-col-reverse xl:flex-row flex-col">
            <div className="flex-1 mt-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-x-5 mt-2">
                  <div className="col-span-12 2xl:col-span-12">
                    <div className="mb-5">
                      <label className="form-label">Название</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите название"
                        name="name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && (
                        <InvalidFeedback text={"Введите название"} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Регион</label>
                      <select
                        className="tom-select w-full"
                        {...register("region")}
                        value={currentRegion}
                        onChange={onChangeRegion}
                      >
                        {regions?.map((cat) => (
                          <option value={cat.id} key={cat.id}>
                            {cat.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Город</label>
                      <select
                        className="tom-select w-full"
                        {...register("city")}
                        value={currentCity}
                        onChange={onChangeCity}
                      >
                        {cities?.map((cat) => (
                          <option value={cat.id} key={cat.id}>
                            {cat.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Адрес</label>
                      <input
                        name="address"
                        type="text"
                        {...register("address", { required: true })}
                        className="form-control"
                        placeholder="Введите адрес"
                      />
                      {errors.address && (
                        <InvalidFeedback text={"Введите адрес"} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Телефон</label>
                      <InputMask
                        mask="+7-999-999-99-99"
                        name="contact"
                        type="text"
                        {...register("contact")}
                        className="form-control"
                        placeholder="Введите телефон"
                      ></InputMask>
                      {errors.contact && (
                        <InvalidFeedback text={"Введите телефон"} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Ссылка</label>
                      <input
                        name="url"
                        type="text"
                        {...register("url")}
                        className="form-control"
                        placeholder="Введите ссылку"
                      />
                      {errors.url && (
                        <InvalidFeedback text={"Введите ссылку"} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Категория</label>
                      <select
                        className="tom-select w-full"
                        {...register("category")}
                      >
                        {categories?.map((cat) => (
                          <option value={cat.id} key={cat.id}>
                            {cat.name}
                          </option>
                        ))}
                      </select>
                      {errors.category && (
                        <InvalidFeedback text={"Выберите категорию"} />
                      )}
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Статус</label>
                      <select
                        className="tom-select w-full"
                        {...register("active")}
                        value={isActive}
                        onChange={onChangeIsActive}
                      >
                        <option value={true}>Активный</option>
                        <option value={false}>Неактивный</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mb-5">
                      <label className="form-label">Статус модерации</label>
                      <select
                        className="tom-select w-full"
                        value={isChecked}
                        onChange={onChangeIsChecked}
                        defaultValue="Разрешить публикацию"
                      >
                        <option value={true}>Разрешить публикацию</option>
                        <option value={false}>На модерации</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-12">
                    <div className="mb-5">
                      <label className="form-label">Описание</label>
                      <textarea
                        className="form-control"
                        placeholder="Введите описание"
                        name="description"
                        {...register("description")}
                      ></textarea>
                      {errors.description && (
                        <InvalidFeedback text={"Введите описание"} />
                      )}
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary w-32 mt-2">
                    Сохранить
                  </button>
                </div>
              </form>
            </div>
            <div className="w-52 mx-auto xl:mr-0 xl:ml-6 mt-14">
              <div className="border-2 border-dashed shadow-sm border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                {newImage ? (
                  <div className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                    <img
                      className="rounded-md"
                      alt="image"
                      src={URL.createObjectURL(newImage)}
                    />
                  </div>
                ) : (
                  <div
                    className={`h-40 relative ${
                      objectData?.main_image ? "image-fit" : ""
                    } mx-auto`}
                  >
                    <img
                      className="rounded-md"
                      alt="image"
                      src={
                        objectData?.main_image
                          ? objectData?.main_image
                          : uploadImg
                      }
                    />
                  </div>
                )}
                <div className="mx-auto cursor-pointer relative mt-5">
                  <button type="button" className="btn btn-primary w-full">
                    Изменить фото
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    className="w-full h-full top-0 left-0 absolute opacity-0"
                    onChange={handleChangeImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralTab
