import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getVideos = createAsyncThunk(
  "videos/getVideos",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(`/video/index.php?category=${id}`)
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getCategories = createAsyncThunk(
  "videos/getCategories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/category/index.php?category=video")
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getPlaylists = createAsyncThunk(
  "videos/getPlaylists",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/video/listPlaylist.php", {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete("/video/index.php", {
        data: { id }
      })

      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createVideo = createAsyncThunk(
  "videos/createVideo",
  async (videoData, thunkAPI) => {
    try {
      const { data } = await axios.post("/video/index.php", videoData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateVideo = createAsyncThunk(
  "videos/updateVideo",
  async (videoData, thunkAPI) => {
    try {
      const { data } = await axios.put("/video/index.php", videoData, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createCategory = createAsyncThunk(
  "books/createCategory",
  async (name, thunkAPI) => {
    try {
      const { data } = await axios.post("/category/index.php", {
        name,
        object: "video"
      })

      return { data, name }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  videos: [],
  categories: [],
  playlists: [],
  status: "loading"
}

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Videos
    [getVideos.pending]: (state) => {
      state.videos = []
      state.status = "loading"
    },
    [getVideos.fulfilled]: (state, action) => {
      state.videos = action.payload
      state.status = "loaded"
    },
    [getVideos.rejected]: (state) => {
      state.videos = []
      state.status = "loading"
    },

    // Get Categories
    [getCategories.pending]: (state) => {
      state.categories = []
      state.status = "loading"
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload
      state.status = "loaded"
    },
    [getCategories.rejected]: (state) => {
      state.categories = []
      state.status = "loading"
    },

    // Get Playlists
    [getPlaylists.pending]: (state) => {
      state.playlists = []
      state.status = "loading"
    },
    [getPlaylists.fulfilled]: (state, action) => {
      state.playlists = action.payload
      state.status = "loaded"
    },
    [getPlaylists.rejected]: (state) => {
      state.playlists = []
      state.status = "loading"
    },

    // Delete Video
    [deleteVideo.pending]: (state) => {
      state.status = "loading"
    },
    [deleteVideo.fulfilled]: (state, action) => {
      state.status = "loaded"
      state.videos = state.videos?.filter(
        (video) => video.id !== action.payload.id
      )

      if (action.payload.error) {
        toast.error("Ошибка при удалении видео!")
      } else {
        toast.success("Видео успешно удалено")
      }
    },
    [deleteVideo.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении видео!")
    },

    // Create Video
    [createVideo.pending]: (state) => {
      state.status = "loading"
    },
    [createVideo.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Видео успешно созданы")
      } else {
        toast.error("Ошибка при создании видео!")
      }
    },
    [createVideo.rejected]: (state) => {
      toast.error("Ошибка при создании видео!")
    },

    // Update Video
    [updateVideo.pending]: (state) => {
      state.status = "loading"
    },
    [updateVideo.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Видео успешно обновлено")
      } else {
        toast.error("Ошибка при обновлении видео!")
      }
    },
    [updateVideo.rejected]: (state) => {
      toast.error("Ошибка при обновлении видео!")
    },

    // Create Category
    [createCategory.pending]: (state) => {
      state.status = "loading"
    },
    [createCategory.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (action.payload.error) {
        toast.error("Ошибка при создании категории!")
      } else {
        toast.success("Категория успешно создана")
        state.categories.push({
          id: action.payload.data.id,
          name: action.payload.name,
          book: null
        })
      }
    },
    [createCategory.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании категории!")
    }
  }
})

export const videoReducer = videoSlice.reducer
