import AudioList from "../views/pages/audios/list"
import AudioCategories from "../views/pages/audios/category"
import AudioCreate from "../views/pages/audios/create"
import AudioView from "../views/pages/audios/view"

import BookList from "../views/pages/books/list"
import BookCategories from "../views/pages/books/category"
import BookCreate from "../views/pages/books/create"
import BookView from "../views/pages/books/view"

import QuestionList from "../views/pages/questions"

import FeedbackList from "../views/pages/feedback"

import ReaderList from "../views/pages/readers/list"
import ReaderCreate from "../views/pages/readers/create"
import ReaderView from "../views/pages/readers/view"

import ObjectListActive from "../views/pages/objects/active/list"
import ObjectActiveView from "../views/pages/objects/active/view"
import ObjectListInactive from "../views/pages/objects/inactive/list"
import ObjectInactiveView from "../views/pages/objects/inactive/view"
import ObjectListModeration from "../views/pages/objects/moderation/list"
import ObjectModerationView from "../views/pages/objects/moderation/view"
import ObjectCreate from "../views/pages/objects/create"

import VideoList from "../views/pages/videos/list"
import VideoCategories from "../views/pages/videos/category"
import VideoCreate from "../views/pages/videos/create"
import VideoCreateSelect from "../views/pages/videos/create/select"
import VideoView from "../views/pages/videos/view"

const privateRoutes = [
  { path: "/feedback", element: <FeedbackList /> },

  //Audios
  { path: "/audio-list", element: <AudioList /> },
  { path: "/audio-create", element: <AudioCreate /> },
  { path: "/audio-categories", element: <AudioCategories /> },
  { path: "/audio-list/:id", element: <AudioView /> },
  //Books
  { path: "/book-list", element: <BookList /> },
  { path: "/book-create", element: <BookCreate /> },
  { path: "/book-categories", element: <BookCategories /> },
  { path: "/book-list/:id", element: <BookView /> },
  //Questions
  { path: "/question-list", element: <QuestionList /> },
  //Readers
  { path: "/reader-list", element: <ReaderList /> },
  { path: "/reader-create", element: <ReaderCreate /> },
  { path: "/reader-list/:id", element: <ReaderView /> },
  //Objects
  { path: "/object-list-active", element: <ObjectListActive /> },
  { path: "/object-list-active/:id", element: <ObjectActiveView /> },
  { path: "/object-list-inactive", element: <ObjectListInactive /> },
  { path: "/object-list-inactive/:id", element: <ObjectInactiveView /> },
  { path: "/object-list-moderation", element: <ObjectListModeration /> },
  { path: "/object-list-moderation/:id", element: <ObjectModerationView /> },
  { path: "/object-create", element: <ObjectCreate /> },

  //Videos
  { path: "/video-create", element: <VideoCreate /> },
  { path: "/video-create/playlist/:id", element: <VideoCreateSelect /> },
  { path: "/video-categories", element: <VideoCategories /> },
  { path: "/video-categories/:id", element: <VideoList /> },
  { path: "/video/:id", element: <VideoView /> }
]

export default privateRoutes
