import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ChevronDown } from "lucide-react"

import { getRequisites, updateRequisites } from "../../../../redux/slices/about"
import InvalidFeedback from "../../../components/InvalidFeedback"

const GeneralTab = () => {
  const dispatch = useDispatch()
  const { requisites } = useSelector((state) => state.about)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    dispatch(updateRequisites(data))
  }

  useEffect(() => {
    reset(requisites)
  }, [requisites])

  useEffect(() => {
    dispatch(getRequisites())
  }, [dispatch])

  return (
    <>
      <div className="tab-pane p-5 active">
        <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
          <div className="font-medium flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
            <ChevronDown className="w-4 h-4 mr-2" />
            Реквизиты
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-x-5 mt-2">
              <div className="col-span-12 2xl:col-span-3 lg:col-span-6">
                <div className="mt-5">
                  <label className="form-label">ИНН</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите ИНН"
                    name="inn"
                    {...register("inn", { required: true })}
                  />
                  {errors.inn && <InvalidFeedback text={"Введите ИНН"} />}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-6">
                <div className="mt-5">
                  <label className="form-label">КПП</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите КПП"
                    name="kpp"
                    {...register("kpp", { required: true })}
                  />
                  {errors.kpp && <InvalidFeedback text={"Введите КПП"} />}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-6">
                <div className="mt-5">
                  <label className="form-label">Банк</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите банк"
                    name="bank"
                    {...register("bank", { required: true })}
                  />
                  {errors.bank && <InvalidFeedback text={"Введите банк"} />}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-6">
                <div className="mt-5">
                  <label className="form-label">БИК</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите БИК"
                    name="bank_bik"
                    {...register("bank_bik", { required: true })}
                  />
                  {errors.bank_bik && <InvalidFeedback text={"Введите БИК"} />}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-4 lg:col-span-6">
                <div className="mt-5">
                  <label className="form-label">Расчетный счет</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите расчетный счет"
                    name="payment_account"
                    {...register("payment_account", { required: true })}
                  />
                  {errors.payment_account && (
                    <InvalidFeedback text={"Введите расчетный счет"} />
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-4 lg:col-span-6">
                <div className="mt-5">
                  <label className="form-label">Корреспондентский счет</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите корреспондентский счет"
                    name="correspondent_account"
                    {...register("correspondent_account", { required: true })}
                  />
                  {errors.correspondent_account && (
                    <InvalidFeedback text={"Введите корреспондентский счет"} />
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-4 lg:col-span-3">
                <div className="mt-5">
                  <label className="form-label">ОГРН</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите ОГРН"
                    name="ogrn"
                    {...register("ogrn", { required: true })}
                  />
                  {errors.ogrn && <InvalidFeedback text={"Введите ОГРН"} />}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-8 lg:col-span-9">
                <div className="mt-5">
                  <label className="form-label">Получатель</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Введите получателя"
                    name="recipient"
                    {...register("recipient", { required: true })}
                  />
                  {errors.recipient && (
                    <InvalidFeedback text={"Введите получателя"} />
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-12">
                <button type="submit" className="btn btn-primary w-32 mt-6">
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default GeneralTab
