import React from "react"
import { Link } from "react-router-dom"
import { Edit, Trash2 } from "lucide-react"
import userPhoto from "../../../../assets/images/man.png"

const Reader = ({ reader, onClickShowDeleteModal }) => {
  return (
    <>
      <tr className="intro-x">
        <td className="!py-3.5">
          <div className="flex items-center">
            {/* <div className="w-9 h-9 image-fit zoom-in mr-4">
              {reader?.image ? (
                <img
                  alt=""
                  className="rounded-lg border-white shadow-md tooltip"
                  src={reader?.image}
                />
              ) : (
                <img
                  alt=""
                  className="rounded-lg border-white shadow-md tooltip"
                  src={userPhoto}
                />
              )}
            </div> */}
            <div className="w-144 truncate">
              <Link
                to={`/reader-list/${reader?.id}`}
                className="font-medium whitespace-nowrap"
              >
                {reader?.surname} {reader?.name}
              </Link>
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {reader?.description}
              </div>
            </div>
          </div>
        </td>

        <td className="table-report__action w-72">
          <div className="flex justify-center items-center">
            <Link
              to={`/reader-list/${reader?.id}`}
              className="flex items-center mr-3"
            >
              <Edit className="w-4 h-4 mr-1"></Edit> Изменить
            </Link>

            <button
              className="flex items-center text-danger"
              onClick={() => onClickShowDeleteModal(reader?.id)}
            >
              <Trash2 className="w-4 h-4 mr-1"></Trash2> Удалить
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default Reader
