import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getUnanswered = createAsyncThunk(
  "questions/getUnanswered",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/topic/unanswered.php")
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getAnswered = createAsyncThunk(
  "questions/getAnswered",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/topic/questions.php")
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getCategories = createAsyncThunk(
  "questions/getCategories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/topic/index.php")
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteQuestion = createAsyncThunk(
  "questions/deleteQuestion",
  async (obj, thunkAPI) => {
    try {
      const { data } = await axios.delete("/questions/index.php", {
        data: {
          id: obj.id
        }
      })
      return { id: obj.id, type: obj.type, error: data.error }
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteCategory = createAsyncThunk(
  "questions/deleteCategory",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete("/topic/index.php", {
        data: {
          id: id
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const addAnswer = createAsyncThunk(
  "questions/addAnswer",
  async (obj, thunkAPI) => {
    try {
      const { data } = await axios.post("/answers/index.php", obj)
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const addQuestionAnswer = createAsyncThunk(
  "questions/addQuestionAnswer",
  async (questionData, thunkAPI) => {
    try {
      const { data } = await axios.post("/questions/create.php", questionData)
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const addCategory = createAsyncThunk(
  "questions/addCategory",
  async (questionData, thunkAPI) => {
    try {
      const { data } = await axios.post("/topic/index.php", questionData)
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateAnswer = createAsyncThunk(
  "questions/updateAnswer",
  async (obj, thunkAPI) => {
    try {
      const { data } = await axios.put("/answers/index.php", {
        id: obj.id,
        answer: obj.answer
      })
      return { error: data.error, id: obj.id, answer: obj.answer }
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  unanswered: [],
  answered: [],
  categories: [],
  status: "loading"
}

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Unanswered Questions
    [getUnanswered.pending]: (state) => {
      state.unanswered = []
      state.status = "loading"
    },
    [getUnanswered.fulfilled]: (state, action) => {
      state.unanswered = action.payload
      state.status = "loaded"
    },
    [getUnanswered.rejected]: (state) => {
      state.unanswered = []
      state.status = "loading"
    },

    // Get Answered Questions
    [getAnswered.pending]: (state) => {
      state.answered = []
      state.status = "loading"
    },
    [getAnswered.fulfilled]: (state, action) => {
      state.answered = action.payload
      state.status = "loaded"
    },
    [getAnswered.rejected]: (state) => {
      state.answered = []
      state.status = "loading"
    },

    // Get Categories
    [getCategories.pending]: (state) => {
      state.categories = []
      state.status = "loading"
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload
      state.status = "loaded"
    },
    [getCategories.rejected]: (state) => {
      state.categories = []
      state.status = "loading"
    },

    // Delete Question
    [deleteQuestion.pending]: (state) => {
      state.status = "loading"
    },
    [deleteQuestion.fulfilled]: (state, action) => {
      state.status = "loaded"

      if (!action.payload.error) {
        toast.success("Вопрос успешно удален")
        if (action.payload.type === "answered") {
          state.answered = state.answered?.filter(
            (question) => question.question.id !== action.payload.id
          )
        } else {
          state.unanswered = state.unanswered?.filter(
            (question) => question.id !== action.payload.id
          )
        }
      } else {
        toast.error("Ошибка при удалении вопроса!")
      }
    },
    [deleteQuestion.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении аудио!")
    },

    // Delete Category
    [deleteCategory.pending]: (state) => {
      state.status = "loading"
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.status = "loaded"

      if (!action.payload.error) {
        toast.success("Категория успешно удалена")
        state.categories = state.categories?.filter(
          (cat) => cat.id !== action.payload.result[0].id
        )
      } else {
        toast.error("Ошибка при удалении категории!")
      }
    },
    [deleteCategory.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении категории!")
    },

    // Add Question and Answer
    [addQuestionAnswer.pending]: (state) => {
      state.status = "loading"
    },
    [addQuestionAnswer.fulfilled]: (state, action) => {
      if (!action.payload.error) {
        toast.success("Вопрос успешно создан")
        state.status = "questionCreated"
      } else {
        toast.error("Ошибка при создании вопроса!")
      }
    },
    [addQuestionAnswer.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании вопроса!")
    },

    // Add Category
    [addCategory.pending]: (state) => {
      state.status = "loading"
    },
    [addCategory.fulfilled]: (state, action) => {
      if (!action.payload.error) {
        toast.success("Категория успешно создана")
        state.status = "categoryCreated"
      } else {
        toast.error("Ошибка при создании категории!")
      }
    },
    [addCategory.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании категории!")
    },

    // Add Answer
    [addAnswer.pending]: (state) => {
      state.status = "loading"
    },
    [addAnswer.fulfilled]: (state, action) => {
      if (!action.payload.error) {
        toast.success("Ответ успешно отправлен")
        state.status = "answerAdded"
      } else {
        toast.error("Ошибка при отправке ответа!")
      }
    },
    [addAnswer.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при отправке ответа!")
    },

    // Update Category
    [updateAnswer.pending]: (state) => {
      state.status = "loading"
    },
    [updateAnswer.fulfilled]: (state, action) => {
      state.status = "loaded"

      if (!action.payload.error) {
        toast.success("Ответ успешно изменен")
        const index = state.answered.findIndex(
          (ans) => ans.question.answer.id === action.payload.id
        )

        state.answered[index].question.answer.answer = action.payload.answer
      } else {
        toast.error("Ошибка при обновлении ответа!")
      }
    },
    [updateAnswer.rejected]: (state) => {
      toast.error("Ошибка при обновлении ответа!")
    }
  }
})

export const questionReducer = questionSlice.reducer
