import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, Search } from 'lucide-react';
import { Link } from 'react-router-dom';

import { getReaders } from '../../../../redux/slices/reader';
import DeleteModal from './DeleteModal';
import Reader from './Reader';

const ReaderList = () => {
  const dispatch = useDispatch();
  const { readers, maxPages } = useSelector((state) => state.reader);
  const [searchValue, setSearchValue] = useState('');
  const [currentReader, setCurrentReader] = useState('');
  const [removeReader, setRemoveReader] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const onChangeReader = (readerLink) => {
    setCurrentReader(readerLink);
  };

  const onClickShowDeleteModal = (reader) => {
    setRemoveReader(reader);
  };

  const prevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const nextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const firstPage = () => {
    setCurrentPage(1);
  };

  const lastPage = () => {
    setCurrentPage(maxPages);
  };

  useEffect(() => {
    dispatch(getReaders(currentPage));
  }, [dispatch, currentPage]);

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <h2 className="text-lg font-medium mr-auto mt-2">Список чтецов</h2>
        </div>
        <div className="intro-y col-span-12 flex flex-wrap xl:flex-nowrap items-center mt-2">
          {/* <div className="flex w-full sm:w-auto">
            <div className="w-56 relative text-slate-500">
              <input
                type="text"
                className="form-control w-56 box pr-10"
                placeholder="Найти чтеца..."
                value={searchValue}
                onChange={onChangeSearchValue}
              />
              <Search className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"></Search>
            </div>
          </div>
          <div className="hidden xl:block mx-auto text-slate-500">
            Всего чтецов: {readers?.length}
          </div> */}
          <div className="w-full xl:w-auto flex flex-wrap xl:flex-nowrap items-center gap-y-3 mt-3 xl:mt-0">
            <Link to="/reader-create" className="btn btn-primary shadow-md mr-2">
              <Plus className="w-4 h-4 mr-2"></Plus> Добавить чтеца
            </Link>
          </div>
        </div>

        {readers?.length ? (
          <>
            <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
              <table className="table table-report -mt-2">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap">Название</th>
                    <th className="text-center whitespace-nowrap">Изменить</th>
                  </tr>
                </thead>
                <tbody>
                  {readers
                    ?.filter((reader) => {
                      const fullName = reader.surname + reader.name;
                      return fullName.toLowerCase().includes(searchValue.toLowerCase());
                    })
                    ?.map((reader, index) => (
                      <Reader
                        key={index}
                        reader={reader}
                        onChangeReader={onChangeReader}
                        currentReader={currentReader}
                        onClickShowDeleteModal={onClickShowDeleteModal}
                      />
                    ))}
                </tbody>
              </table>
            </div>
            <div class="flex flex-wrap items-center col-span-12 intro-y sm:flex-row sm:flex-nowrap">
              <nav class="w-full sm:w-auto sm:mr-auto">
                <ul class="flex w-full mr-0 sm:w-auto sm:mr-auto items-center">
                  <li class="flex-1 sm:flex-initial">
                    <button
                      onClick={firstPage}
                      disabled={currentPage === 1}
                      class="transition duration-200 border py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide-icon lucide lucide-chevrons-left stroke-[1.3] w-4 h-4">
                        <polyline points="11 17 6 12 11 7"></polyline>
                        <polyline points="18 17 13 12 18 7"></polyline>
                      </svg>
                    </button>
                  </li>
                  <li class="flex-1 sm:flex-initial">
                    <button
                      onClick={prevPage}
                      disabled={currentPage === 1}
                      class="transition duration-200 border py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide-icon lucide lucide-chevron-left stroke-[1.3] w-4 h-4">
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                  </li>

                  {currentPage > 1 && (
                    <li class="flex-1 sm:flex-initial">
                      <button
                        onClick={prevPage}
                        class="transition duration-200 border py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">
                        {currentPage - 1}
                      </button>
                    </li>
                  )}
                  <li class="flex-1 sm:flex-initial">
                    <button
                      style={{ backgroundColor: '#fff', padding: '5px 10px' }}
                      class="transition duration-200 border py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3 !box font-medium dark:bg-darkmode-400">
                      {currentPage}
                    </button>
                  </li>
                  {currentPage < maxPages && (
                    <li class="flex-1 sm:flex-initial">
                      <button
                        onClick={nextPage}
                        class="transition duration-200 border py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">
                        {currentPage + 1}
                      </button>
                    </li>
                  )}

                  <li class="flex-1 sm:flex-initial">
                    <button
                      onClick={nextPage}
                      disabled={currentPage === maxPages}
                      class="transition duration-200 border py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide-icon lucide lucide-chevron-right stroke-[1.3] w-4 h-4">
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  </li>

                  <li class="flex-1 sm:flex-initial">
                    <button
                      onClick={lastPage}
                      disabled={currentPage === maxPages}
                      class="transition duration-200 border py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide-icon lucide lucide-chevrons-right stroke-[1.3] w-4 h-4">
                        <polyline points="13 17 18 12 13 7"></polyline>
                        <polyline points="6 17 11 12 6 7"></polyline>
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        ) : (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <div className="alert alert-primary show mb-2 mt-2">
              <div>Загрузка...</div>
            </div>
          </div>
        )}
      </div>

      {removeReader && (
        <DeleteModal onClickShowDeleteModal={onClickShowDeleteModal} removeReader={removeReader} />
      )}
    </>
  );
};

export default ReaderList;
