import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import InvalidFeedback from "../../../components/InvalidFeedback"
import { addAnswer, deleteQuestion } from "../../../../redux/slices/question"

const View = ({ showView, onChangeTab }) => {
  const { categories } = useSelector((state) => state.question)
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    dispatch(
      addAnswer({
        question: showView?.id,
        answer: data?.answer,
        topic: parseInt(data?.topic, 10)
      })
    )
    onChangeTab(2)
  }

  const onClickDelete = (id) => {
    dispatch(deleteQuestion({ id, type: "unanswered" }))
    onChangeTab(2)
  }

  return (
    <>
      <div className="intro-y p-5 box">
        <h2 className="intro-y font-medium text-xl sm:text-lg">
          {showView?.question}
        </h2>
        <div className="intro-y text-slate-600 dark:text-slate-500 mt-3 text-xs sm:text-sm">
          {showView?.date_create} <span className="mx-1">•</span>
          <span className="text-primary">{showView?.topic.title}</span>
        </div>

        <div className="intro-y mt-5 pt-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <div className="text-base sm:text-lg font-medium">Ваш ответ</div>
          <div className="news__input relative mt-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 2xl:col-span-4">
                  <div>
                    <label className="form-label">Категория</label>
                    <select
                      className="tom-select w-full"
                      {...register("topic", { required: true })}
                      defaultValue={showView?.topic.title}
                    >
                      {categories?.map((cat) => (
                        <option key={cat?.id}>{cat?.title}</option>
                      ))}
                    </select>
                    {errors.topic && (
                      <InvalidFeedback text={"Выберите категорию"} />
                    )}
                  </div>
                </div>

                <div className="col-span-12">
                  <div className="mt-5">
                    <label className="form-label">Ответ</label>
                    <textarea
                      className="form-control"
                      placeholder="Введите ответ"
                      name="answer"
                      rows="6"
                      {...register("answer", { required: true })}
                    ></textarea>
                    {errors.answer && (
                      <InvalidFeedback text={"Введите ваш ответ"} />
                    )}
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-32 mt-5 mr-5">
                Сохранить
              </button>

              <button
                type="button"
                className="btn btn-outline-danger w-32 mt-5"
                onClick={() => onClickDelete(showView?.id)}
              >
                Удалить
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default View
