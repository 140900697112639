import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getAbout = createAsyncThunk("about/getAbout", async (thunkAPI) => {
  try {
    const { data } = await axios.get("/about/index.php?id=11")
    return data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const getRequisites = createAsyncThunk(
  "about/getRequisites",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/about/requisites.php")
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getSocials = createAsyncThunk(
  "about/getSocials",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/network/index.php")
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateMainInfo = createAsyncThunk(
  "about/updateMainInfo",
  async (aboutData, thunkAPI) => {
    try {
      const { data } = await axios.put("/video/index.php", aboutData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateRequisites = createAsyncThunk(
  "about/updateRequisites",
  async (requisitesData, thunkAPI) => {
    try {
      const { data } = await axios.put(
        "/about/requisites.php",
        requisitesData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateSocial = createAsyncThunk(
  "about/updateSocial",
  async (socialData, thunkAPI) => {
    try {
      const { data } = await axios.put("/network/index.php", socialData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createSocial = createAsyncThunk(
  "about/createSocial",
  async (socialData, thunkAPI) => {
    try {
      const { data } = await axios.post("/network/index.php", socialData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteSocial = createAsyncThunk(
  "about/deleteSocial",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/network/index.php?id=${id}`)
      return { error: data.error, id }
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  about: {},
  requisites: {},
  socials: [],
  status: "loading"
}

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers: {
    // Get About
    [getAbout.pending]: (state) => {
      state.about = {}
      state.status = "loading"
    },
    [getAbout.fulfilled]: (state, action) => {
      state.about = action.payload
      state.status = "loaded"
    },
    [getAbout.rejected]: (state) => {
      state.about = {}
      state.status = "loading"
    },

    // Get Categories
    [getRequisites.pending]: (state) => {
      state.requisites = {}
      state.status = "loading"
    },
    [getRequisites.fulfilled]: (state, action) => {
      state.requisites = action.payload?.[0]
      state.status = "loaded"
    },
    [getRequisites.rejected]: (state) => {
      state.requisites = {}
      state.status = "loading"
    },

    // Get Socials
    [getSocials.pending]: (state) => {
      state.socials = []
      state.status = "loading"
    },
    [getSocials.fulfilled]: (state, action) => {
      state.socials = action.payload
      state.status = "loaded"
    },
    [getSocials.rejected]: (state) => {
      state.socials = []
      state.status = "loading"
    },

    // Update Main Info
    [updateMainInfo.pending]: (state) => {
      state.status = "loading"
    },
    [updateMainInfo.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Данные успешно обновлены")
      } else {
        toast.error("Ошибка при обновлении данных!")
      }
    },
    [updateMainInfo.rejected]: (state) => {
      toast.error("Ошибка при обновлении данных!")
    },

    // Update Requisites
    [updateRequisites.pending]: (state) => {
      state.status = "loading"
    },
    [updateRequisites.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Реквизиты успешно обновлены")
      } else {
        toast.error("Ошибка при обновлении реквизитов!")
      }
    },
    [updateRequisites.rejected]: (state) => {
      toast.error("Ошибка при обновлении реквизитов!")
    },

    // Create Social
    [createSocial.pending]: (state) => {
      state.status = "loading"
    },
    [createSocial.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Соц.сеть успешно создана")
      } else {
        toast.error("Ошибка при создании соц.сети!")
      }
    },
    [createSocial.rejected]: (state) => {
      toast.error("Ошибка при создании соц.сети!")
    },

    // Delete Social
    [deleteSocial.pending]: (state) => {
      state.status = "loading"
    },
    [deleteSocial.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Социальная сеть успешно удалена")
        state.socials = state.socials?.filter(
          (social) => social.id !== action.payload.id
        )
      } else {
        toast.error("Ошибка при удалении социальной сети!")
      }
    },
    [deleteSocial.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при удалении социальной сети!")
    },

    // Update Social
    [updateSocial.pending]: (state) => {
      state.status = "loading"
    },
    [updateSocial.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Социальная сеть успешно обновлена")
      } else {
        toast.error("Ошибка при обновлении социальной сети!")
      }
    },
    [updateSocial.rejected]: (state) => {
      toast.error("Ошибка при обновлении социальной сети!")
    }
  }
})

export const aboutReducer = aboutSlice.reducer
