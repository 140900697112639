import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getMe } from "./redux/slices/auth"
import Layout from "./views/layout"
import Login from "./views/pages/login"
import Register from "./views/pages/register"
import NotFound from "./views/pages/notfound"
import privateRoutes from "./utils/routes"
import RoleAccess from "./views/layout/RoleAccess"
import Dashboard from "./views/pages/dashboard"
import Profile from "./views/pages/profile"

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMe())
  }, [dispatch])

  return (
    <>
      <div className="theme-2">
        <div className="py-5 md:py-0 bg-black/[0.15] dark:bg-transparent">
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* Public Routes */}
              <Route element={<RoleAccess roles={[]} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
              </Route>

              {/* Private Routes */}
              <Route
                element={<RoleAccess roles={["superadmin", "administrator"]} />}
              >
                {privateRoutes.map((route, i) => (
                  <Route path={route.path} element={route.element} key={i} />
                ))}
              </Route>
            </Route>

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
        ></ToastContainer>
      </div>
    </>
  )
}

export default App
