import React from "react"
import { User } from "lucide-react"

const MessageItem = ({ message, onClickItem }) => {
  return (
    <>
      <div className="intro-y" onClick={() => onClickItem(message)}>
        <div className="inbox__item inbox__item--active inline-block sm:block text-slate-600 dark:text-slate-500 bg-slate-100 dark:bg-darkmode-400/70 border-b border-slate-200/60 dark:border-darkmode-400">
          <div className="flex px-5 py-3">
            <div className="w-72 flex-none flex items-center mr-5">
              <a className="w-5 h-5 flex-none flex items-center justify-center text-slate-400">
                <User className="w-4 h-4" />
              </a>

              <div className="inbox__item--sender truncate ml-3">
                {message?.fio}
              </div>
            </div>
            <div className="w-64 sm:w-auto truncate">
              <span className="inbox__item--highlight mr-3">
                {message?.title}
              </span>
              {message?.description}
            </div>
            <div className="inbox__item--time whitespace-nowrap ml-auto pl-10">
              {message?.date_create}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageItem
