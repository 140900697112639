import { CalendarClock, FileText } from "lucide-react"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import axios from "../../../../../utils/axios"
import GeneralTab from "./GeneralTab"
import RequisitesTab from "./RequisitesTab"
import GalleryTab from "./GalleryTab"

const ObjectView = () => {
  const params = useParams()
  const [objectData, setObjectData] = useState(null)
  const [activeTab, setActiveTab] = useState(1)
  const [currentLang, setCurrentLang] = useState("ru")

  const onChangeLang = (e) => {
    setCurrentLang(e.value)
  }

  const fetchObject = useCallback(
    async (lang) => {
      const { data } = await axios.get(`/object/inactive.php?id=${params.id}`, {
        headers: {
          "Accept-Language": lang
        }
      })
      setObjectData({
        id: data.result[0]?.id,
        name: data.result[0]?.name,
        category: data.result[0]?.category.id,
        photo: data.result[0]?.photo,
        sheldule: data.result[0]?.sheldule,
        contact: data.result[0]?.contact?.[0],
        description: data.result[0]?.description,
        url: data.result[0]?.url,
        region: data.result[0]?.map.city.region.id,
        city: data.result[0]?.map.city.id,
        address: data.result[0]?.map.address,
        main_image: data.result[0]?.main_image,
        director: data.result[0]?.director,
        active: data.result[0]?.active,
        lang: currentLang
      })
    },
    [params.id]
  )

  useEffect(() => {
    fetchObject(currentLang)
  }, [fetchObject, currentLang])

  return (
    <>
      {objectData ? (
        <>
          <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 className="text-lg font-medium mr-auto">
              Редактирование объекта "{objectData?.name}"
            </h2>
          </div>
          <div className="pos intro-y grid grid-cols-12 gap-5 mt-5">
            <div className="intro-y col-span-12 lg:col-span-12">
              <div className="post intro-y overflow-hidden box">
                <ul className="post__tabs nav nav-tabs flex-col sm:flex-row bg-slate-200 dark:bg-darkmode-800">
                  <li className="nav-item">
                    <button
                      className={`nav-link tooltip w-full sm:w-52 py-4 ${
                        activeTab === 1 ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(1)}
                    >
                      <FileText className="w-4 h-4 mr-2" />
                      Основные данные
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link tooltip w-full sm:w-52 py-4 ${
                        activeTab === 2 ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(2)}
                    >
                      <CalendarClock className="w-4 h-4 mr-2" /> Режим работы
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link tooltip w-full sm:w-52 py-4 ${
                        activeTab === 3 ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(3)}
                    >
                      <CalendarClock className="w-4 h-4 mr-2" /> Фотографии
                    </button>
                  </li>
                </ul>
                <div className="post__content tab-content">
                  {activeTab === 2 ? (
                    <RequisitesTab objectData={objectData} />
                  ) : activeTab === 3 ? (
                    <GalleryTab objectData={objectData} />
                  ) : (
                    <GeneralTab
                      objectData={objectData}
                      onChangeLang={onChangeLang}
                      currentLang={currentLang}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
          <div className="alert alert-primary show mb-2 mt-8">
            <div>Такого объекта не существует</div>
          </div>
        </div>
      )}
    </>
  )
}

export default ObjectView
