import React from "react"

import Banner from "./Banner"
import Applications from "./Applications"
import ObjectRequest from "./ObjectRequest"

const UserDashboard = () => {
  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12">
          <div className="grid grid-cols-12 gap-6">
            <Banner />

            <Applications />

            <ObjectRequest />
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDashboard
