import React from "react"
import { Outlet } from "react-router-dom"
import Header from "./Header"
import Sidebar from "./Sidebar"
import MobileMenu from "./MobileMenu"

const AdminLayout = () => {
  return (
    <>
      <MobileMenu />
      <div className="flex mt-[4.7rem] md:mt-0 overflow-hidden">
        <Sidebar />
        <div className="content">
          <Header />
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default AdminLayout
