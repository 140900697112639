import React from "react"
import { XCircle } from "lucide-react"
import { useDispatch } from "react-redux"
import { deleteObject } from "../../../../../redux/slices/object"

const DeleteModal = ({ onClickShowDeleteModal, removeObject }) => {
  const dispatch = useDispatch()

  const onClickDelete = () => {
    dispatch(deleteObject(removeObject))
    onClickShowDeleteModal(null)
  }

  return (
    <div className="modal overflow-y-auto show">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="p-5 text-center">
              <XCircle
                className="w-16 h-16 text-danger mx-auto mt-3"
                onClick={() => onClickShowDeleteModal(null)}
              ></XCircle>
              <div className="text-3xl mt-5">Вы уверены?</div>
              <div className="text-slate-500 mt-2">
                Удалить объект
                <br />
                без возможности восстановления
              </div>
            </div>
            <div className="px-5 pb-8 text-center">
              <button
                type="button"
                className="btn btn-outline-secondary w-24 mr-1"
                onClick={() => onClickShowDeleteModal(null)}
              >
                Отмена
              </button>
              <button
                type="button"
                className="btn btn-danger w-24"
                onClick={onClickDelete}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal
