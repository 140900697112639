import React from "react"
import { ExternalLink } from "lucide-react"
import { Link } from "react-router-dom"

const Category = ({ category }) => {
  return (
    <>
      <tr>
        <td className="!py-3.5">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="font-medium whitespace-nowrap">
                {category?.name}
              </div>
            </div>
          </div>
        </td>
        <td className="!py-3.5 w-16">
          <Link to={`/video-categories/${category?.id}`}>
            <ExternalLink className="w-4 h-4 mr-1" />
          </Link>
        </td>
      </tr>
    </>
  )
}

export default Category
