import React, { useEffect, useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import Select from "react-select"

import { updateCategory } from "../../../../redux/slices/audio"
import InvalidFeedback from "../../../components/InvalidFeedback"
import { languages } from "../../../../utils/languages"
import axios from "../../../../utils/axios"

const EditModal = ({ onClickShowEditModal, editCategory }) => {
  const dispatch = useDispatch()
  const [catData, setCatData] = useState(null)
  const [currentLang, setCurrentLang] = useState("ru")
  const [currentCatName, setCurrentCatName] = useState("")

  const fetchCategory = useCallback(async (lang) => {
    const { data } = await axios.get("/category/index.php?category=audio", {
      headers: { "Accept-Language": lang }
    })
    if (!data?.error) {
      const categoryFilter = data?.result?.find(
        (item) => item?.id === editCategory?.id
      )
      setCurrentCatName(categoryFilter?.name)
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    dispatch(
      updateCategory({
        id: editCategory?.id,
        name: currentCatName,
        lang: currentLang,
        object: "audio"
      })
    )
    onClickShowEditModal(null)
    console.log({
      id: editCategory?.id,
      name: currentCatName,
      lang: currentLang,
      object: "audio"
    })
  }

  const onChangeLang = (e) => {
    setCurrentLang(e.value)
  }

  useEffect(() => {
    fetchCategory(currentLang)
  }, [fetchCategory, currentLang])

  return (
    <>
      <div className="modal overflow-y-auto show">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header flex items-center">
              <h2 className="font-medium text-base mr-auto">Редактирование</h2>
              <Select
                placeholder="Выберите язык"
                className="basic-single-select"
                value={languages?.filter((obj) => obj.value === currentLang)}
                options={languages}
                onChange={onChangeLang}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                <div className="col-span-12 sm:col-span-12">
                  <label className="form-label">Название категории</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Введите название"
                    value={currentCatName}
                    onChange={(e) => setCurrentCatName(e.target.value)}
                  />
                  {errors.name && <InvalidFeedback text={"Введите название"} />}
                </div>
              </div>

              <div className="modal-footer">
                <input
                  name="lang"
                  type="hidden"
                  className="form-control"
                  value={currentLang}
                  {...register("lang")}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary w-28 mr-2"
                  onClick={() => onClickShowEditModal(null)}
                >
                  Отменить
                </button>
                <button type="submit" className="btn btn-primary w-28">
                  Сохранить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditModal
