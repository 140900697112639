import React from "react"
import { useSelector } from "react-redux"
import AdminDashboard from "./admin"
import UserDashboard from "./user"

const Dashboard = () => {
  const { user } = useSelector((state) => state.auth)

  const renderDashboard = () => {
    if (
      user?.role?.type === "administrator" ||
      user?.role?.type === "superadmin"
    ) {
      return <AdminDashboard />
    } else {
      return <UserDashboard />
    }
  }
  return <>{renderDashboard()}</>
}

export default Dashboard
