import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post("/auth/auth.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      if (data.access) {
        window.localStorage.setItem("token", data.access)
      }
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/registration/newUser.php",
        { ...formData, city: 1 },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      if (data.access) {
        window.localStorage.setItem("token", data.access)
      }
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getMe = createAsyncThunk("auth/getMe", async (thunkAPI) => {
  try {
    const { data } = await axios.get("/users/index.php")
    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (userData, thunkAPI) => {
    try {
      const { data } = await axios.put("/users/index.php", userData)

      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const uploadAvatar = createAsyncThunk(
  "auth/uploadAvatar",
  async (image, thunkAPI) => {
    try {
      const { data } = await axios.post("/users/avatarUpload.php", image, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (passData, thunkAPI) => {
    try {
      const { data } = await axios.post("/users/changePass.php", passData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  user: null,
  status: "loading"
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null
      state.status = null
      localStorage.removeItem("token")
    }
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.status = "loading"
      state.user = null
    },
    [loginUser.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        state.user = action.payload.result
      } else {
        state.user = null
        toast.error("Неверный логин или пароль")
      }
    },
    [loginUser.rejected]: (state) => {
      state.status = "error"
      state.user = null
      toast.error("Неверный логин или пароль")
    },

    //Register User
    [registerUser.pending]: (state) => {
      state.status = "loading"
      state.user = null
    },
    [registerUser.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Регистрация прошла успешно")
      } else {
        toast.error("Ошибка при регистрации!")
      }
    },
    [registerUser.rejected]: (state) => {
      state.status = "error"
      state.user = null
    },

    // Get User
    [getMe.pending]: (state) => {
      state.status = "loading"
      state.user = null
    },
    [getMe.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        state.user = action.payload.result?.[0]
      } else {
        state.user = null
      }
    },
    [getMe.rejected]: (state) => {
      state.status = "error"
      state.user = null
    },

    // Update Profile
    [updateProfile.pending]: (state) => {
      state.status = "loading"
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Данные успешно обновлены")
      } else {
        toast.error("Ошибка при обновлении данных!")
      }
    },
    [updateProfile.rejected]: (state) => {
      toast.error("Ошибка при обновлении данных!")
    },

    // Update Profile
    [uploadAvatar.pending]: (state) => {
      state.status = "loading"
    },
    [uploadAvatar.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Картинка успешно загружена")
      } else {
        toast.error("Ошибка при загрузки картинки!")
      }
    },
    [uploadAvatar.rejected]: (state) => {
      toast.error("Ошибка при загрузки картинки!")
    },

    // Change Password
    [changePassword.pending]: (state) => {
      state.status = "loading"
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Пароль успешно изменен")
      } else {
        toast.error("Ошибка при обновлении пароля!")
      }
    },
    [changePassword.rejected]: (state) => {
      toast.error("Ошибка при обновлении пароля!")
    }
  }
})

export const checkIsAuth = (state) => Boolean(state.auth.user)

export const authReducer = authSlice.reducer

export const { logout } = authSlice.actions
