import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getActiveObjects = createAsyncThunk(
  "objects/getActiveObjects",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.get(`/object/index.php?category=${id}`)
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getInactiveObjects = createAsyncThunk(
  "objects/getInactiveObjects",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/object/inactive.php")
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getModerationObjects = createAsyncThunk(
  "objects/getModerationObjects",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/object/moderation.php")
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getObjectById = createAsyncThunk(
  "objects/getObjectById",
  async (objId, thunkAPI) => {
    try {
      const { data } = await axios.get(`/object/index.php?id=${objId}`)
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteObject = createAsyncThunk(
  "objects/deleteObject",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/object/index.php?id=${id}`)

      return { error: data.error, id }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

// export const createObject = createAsyncThunk(
//   "objects/createObject",
//   async (objectData, thunkAPI) => {
//     try {
//       const { data } = await axios.post("/object/index.php", objectData, {
//         headers: {
//           "Content-Type": "multipart/form-data"
//         }
//       })
//       return data
//     } catch (error) {
//       console.log(error)
//       return thunkAPI.rejectWithValue(error.message)
//     }
//   }
// )

export const createObject = createAsyncThunk(
  "objects/createObject",
  async (objectData, thunkAPI) => {
    try {
      const { data } = await axios.post("/object/create.php", objectData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateObject = createAsyncThunk(
  "objects/updateObject",
  async (objectData, thunkAPI) => {
    try {
      const { data } = await axios.post("/object/updateNew.php", objectData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getCategories = createAsyncThunk(
  "audios/getCategories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/category/index.php?category=object")
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateSchedule = createAsyncThunk(
  "objects/updateSchedule",
  async (scheduleData, thunkAPI) => {
    try {
      const { data } = await axios.put("/object/sheldule.php", scheduleData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const changeStatus = createAsyncThunk(
  "objects/changeStatus",
  async (statusData, thunkAPI) => {
    try {
      const { data } = await axios.put("/object/status.php", statusData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  active: [],
  inactive: [],
  moderation: [],
  categories: [],
  statusActive: "loading",
  statusInactive: "loading",
  statusModeration: "loading"
}

const objectSlice = createSlice({
  name: "object",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Active Objects
    [getActiveObjects.pending]: (state) => {
      state.active = []
      state.statusActive = "loading"
    },
    [getActiveObjects.fulfilled]: (state, action) => {
      state.active = action.payload.result
      state.statusActive = "loaded"
    },
    [getActiveObjects.rejected]: (state) => {
      state.active = []
      state.statusActive = "loading"
    },

    // Get Inactive Objects
    [getInactiveObjects.pending]: (state) => {
      state.inactive = []
      state.statusInactive = "loading"
    },
    [getInactiveObjects.fulfilled]: (state, action) => {
      state.inactive = action.payload.result
      state.statusInactive = "loaded"
    },
    [getInactiveObjects.rejected]: (state) => {
      state.inactive = []
      state.statusInactive = "loading"
    },

    // Get Moderation Objects
    [getModerationObjects.pending]: (state) => {
      state.moderation = []
      state.statusModeration = "loading"
    },
    [getModerationObjects.fulfilled]: (state, action) => {
      state.moderation = action.payload.result
      state.statusModeration = "loaded"
    },
    [getModerationObjects.rejected]: (state) => {
      state.moderation = []
      state.statusModeration = "loading"
    },

    // Delete Object
    [deleteObject.pending]: (state) => {
      state.status = "loading"
    },
    [deleteObject.fulfilled]: (state, action) => {
      state.status = "deleted"

      if (!action.payload.error) {
        toast.success("Объект успешно удален")
      } else {
        toast.error("Ошибка при удалении объекта!")
      }
    },
    [deleteObject.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении объекта!")
    },

    // Create Object
    [createObject.pending]: (state) => {
      state.status = "loading"
    },
    [createObject.fulfilled]: (state, action) => {
      state.status = "created"
      if (!action.payload.error) {
        toast.success("Объект успешно создан")
      } else {
        toast.error("Ошибка при создании объекта!")
      }
    },
    [createObject.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании объекта!")
    },

    // Update Object
    [updateObject.pending]: (state) => {
      state.status = "loading"
    },
    [updateObject.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Объект успешно обновлен")
      } else {
        toast.error("Ошибка при обновлении объекта!")
      }
    },
    [updateObject.rejected]: (state) => {
      toast.error("Ошибка при обновлении объекта!")
    },

    // Get Categories
    [getCategories.pending]: (state) => {
      state.categories = []
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.result
    },
    [getCategories.rejected]: (state) => {
      state.categories = []
    },

    // Create Schedule
    [updateSchedule.pending]: (state) => {
      state.status = "loading"
    },
    [updateSchedule.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Расписание успешно обновлено")
      } else {
        toast.error("Ошибка при обновлении расписания!")
      }
    },
    [updateSchedule.rejected]: (state) => {
      toast.error("Ошибка при обновлении расписания!")
    }
  }
})

export const objectReducer = objectSlice.reducer
