import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ChevronDown, Image, X } from "lucide-react"
import axios from "../../../../../utils/axios"
import { updateObject } from "../../../../../redux/slices/object"

const GeneralTab = ({ objectData }) => {
  const dispatch = useDispatch()
  const [currentImages, setCurrentImages] = useState([])

  const handleImageChange = async (e) => {
    if (e.target.files) {
      const formData = new FormData()
      Array.from(e.target.files).map((file) => formData.append("image[]", file))

      try {
        const uploadedImages = await axios.post(
          "/object/uploadImage.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        if (!uploadedImages?.data?.error) {
          setCurrentImages((oldArray) => [
            ...oldArray,
            ...uploadedImages?.data?.result
          ])
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const removeImage = (index) => {
    setCurrentImages([
      ...currentImages.slice(0, index),
      ...currentImages.slice(index + 1, currentImages.length)
    ])
  }

  const uploadToServer = async () => {
    const newObjectData = {
      id: objectData?.id,
      name: objectData?.name,
      category: objectData?.category,
      photo: currentImages,
      sheldule: objectData?.sheldule,
      contact: objectData?.contact,
      url: objectData?.url,
      description: objectData?.description,
      city: objectData?.city,
      address: objectData?.address,
      region: objectData?.region,
      main_image: objectData?.main_image,
      active: objectData?.active
    }

    dispatch(updateObject(newObjectData))
  }

  useEffect(() => {
    setCurrentImages(objectData?.photo)
  }, [])

  return (
    <>
      <div className="tab-pane p-5 active">
        <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
          <div className="font-medium text-base flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
            <ChevronDown className="w-4 h-4 mr-2"></ChevronDown> Галерея
            фотография
          </div>
          <div className="mt-5">
            <div className="form-inline items-start flex-col xl:flex-row mt-10">
              <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                <div className="flex flex-wrap items-center gap-5 pl-4 pr-5">
                  {currentImages
                    ? currentImages?.map((photo, i) => (
                        <div
                          className="w-48 h-32 relative image-fit cursor-pointer zoom-in"
                          key={i}
                        >
                          <img className="rounded-md" alt="img" src={photo} />
                          <div className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-danger right-0 top-0 -mr-2 -mt-2">
                            <X
                              className="w-4 h-4"
                              onClick={() => removeImage(i)}
                            ></X>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
                <div className="px-4 pb-4 mt-5 flex items-center justify-center cursor-pointer relative">
                  <Image className="w-4 h-4 mr-2"></Image>
                  <span className="text-primary mr-1">
                    Загрузить новую картинку
                  </span>

                  <input
                    type="file"
                    accept="image/*"
                    name="image[]"
                    multiple
                    onChange={handleImageChange}
                    className="w-full h-full top-0 left-0 absolute opacity-0"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-32 mt-5"
              onClick={uploadToServer}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralTab
