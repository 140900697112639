import React from "react"
import { useSelector } from "react-redux"
import AdminLayout from "./admin"
import UserLayout from "./user"

const Layout = () => {
  const { user } = useSelector((state) => state.auth)

  const renderLayout = () => {
    if (
      user?.role?.type === "administrator" ||
      user?.role?.type === "superadmin"
    ) {
      return <AdminLayout />
    } else {
      return <UserLayout />
    }
  }

  return <>{renderLayout()}</>
}

export default Layout
