import React from "react"
import Item from "./Item"
import View from "./View"

const UnansweredTab = ({ unanswered, showView, onClickItem, onChangeTab }) => {
  return (
    <>
      {showView ? (
        <View showView={showView} onChangeTab={onChangeTab} />
      ) : (
        <div className="intro-y inbox box">
          <div className="p-5 flex flex-col-reverse sm:flex-row text-slate-500 border-b border-slate-200/60">
            <div className="flex items-center sm:mr-auto">
              <div className="">Всего вопросов: {unanswered?.length}</div>
            </div>
          </div>
          <div className="overflow-x-auto sm:overflow-x-visible">
            {unanswered ? (
              unanswered?.map((item, i) => (
                <Item data={item} key={i} onClickItem={onClickItem} />
              ))
            ) : (
              <p className="p-5">Вопросов пока нет...</p>
            )}
          </div>
          <div className="p-5 flex flex-col sm:flex-row items-center text-center sm:text-left text-slate-500"></div>
        </div>
      )}
    </>
  )
}

export default UnansweredTab
