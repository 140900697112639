import React from "react"
import { useDispatch } from "react-redux"
import { CheckCheck, HelpCircle, Plus, Trash2 } from "lucide-react"
import { deleteCategory } from "../../../redux/slices/question"

const Sidebar = ({ categories, onChangeTab, activeTab }) => {
  const dispatch = useDispatch()

  return (
    <>
      <div className="col-span-12 lg:col-span-3 2xl:col-span-3">
        <div className="intro-y box p-5">
          <div className="mt-1">
            <button
              className={`flex items-center px-3 py-2 w-full rounded-md ${
                activeTab === 1 ? "bg-primary text-white font-medium" : ""
              } `}
              onClick={() => onChangeTab(1)}
            >
              <CheckCheck className="w-4 h-4 mr-2" /> Все вопросы
            </button>

            <button
              className={`flex items-center px-3 py-2 mt-2 w-full rounded-md ${
                activeTab === 2 ? "bg-primary text-white font-medium" : ""
              }`}
              onClick={() => onChangeTab(2)}
            >
              <HelpCircle className="w-4 h-4 mr-2" /> Ожидают ответа
            </button>

            <button
              className={`flex items-center px-3 py-2 mt-2 w-full rounded-md ${
                activeTab === 3 ? "bg-primary text-white font-medium" : ""
              }`}
              onClick={() => onChangeTab(3)}
            >
              <Plus className="w-4 h-4 mr-2" /> Добавить вопрос
            </button>

            <button
              className={`flex items-center px-3 py-2 mt-2 w-full rounded-md ${
                activeTab === 4 ? "bg-primary text-white font-medium" : ""
              }`}
              onClick={() => onChangeTab(4)}
            >
              <Plus className="w-4 h-4 mr-2" /> Новая категория
            </button>
          </div>
          <div className="border-t border-slate-200 dark:border-darkmode-400 mt-4 pt-4">
            {categories.length ? (
              categories?.map((cat) => (
                <div
                  className="flex items-center px-1 py-2 truncate"
                  key={cat?.id}
                >
                  <div className="w-8 h-8 mr-2 flex-none image-fit relative">
                    <img alt="Cat" className="rounded-full" src={cat?.image} />
                  </div>

                  <div className="w-full sm:w-auto relative mr-auto">
                    {cat?.title}
                  </div>
                  <Trash2
                    className="w-4 h-4 text-danger on-hover"
                    onClick={() => dispatch(deleteCategory(cat?.id))}
                  />
                </div>
              ))
            ) : (
              <div className="flex items-center px-1 py-2 truncate">
                <div className="w-full sm:w-auto relative mr-auto">
                  Категорий пока нет
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
