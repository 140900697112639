import { configureStore } from "@reduxjs/toolkit"
import { authReducer } from "./slices/auth"
import { aboutReducer } from "./slices/about"
import { objectReducer } from "./slices/object"
import { audioReducer } from "./slices/audio"
import { questionReducer } from "./slices/question"
import { bookReducer } from "./slices/book"
import { messageReducer } from "./slices/message"
import { readerReducer } from "./slices/reader"
import { videoReducer } from "./slices/video"

const store = configureStore({
  reducer: {
    auth: authReducer,
    about: aboutReducer,
    object: objectReducer,
    audio: audioReducer,
    question: questionReducer,
    book: bookReducer,
    message: messageReducer,
    reader: readerReducer,
    video: videoReducer
  }
})

export default store
