import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { loginUser, checkIsAuth } from "../../../redux/slices/auth"

import logo from "../../../assets/images/logo/logo-no-bg.svg"
import InvalidFeedback from "../../components/InvalidFeedback"

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isAuth = useSelector(checkIsAuth)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (isAuth) navigate("/")
  }, [isAuth, navigate])

  const onSubmit = (data) => {
    try {
      dispatch(loginUser(data))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="login">
        <div className="container sm:px-10">
          <div className="block xl:grid grid-cols-2 gap-4">
            <div className="hidden xl:flex flex-col min-h-screen">
              <div className="my-auto">
                <img
                  alt="ДУМ РБ"
                  className="-intro-x w-2/5 -mt-16"
                  src={logo}
                />
                <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                  Вход в личный кабинет
                </div>
                <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
                  Духовное управление мусульман РБ
                </div>
              </div>
            </div>

            <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
              <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                  Авторизация
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="intro-x mt-8">
                    <div
                      className={`input-form mt-3 ${
                        errors.login ? "has-error" : ""
                      }`}
                    >
                      <label className="form-label w-full flex flex-col sm:flex-row">
                        Email
                      </label>
                      <input
                        type="text"
                        className="intro-x login__input form-control py-3 px-4 block"
                        placeholder="Введите Email"
                        {...register("login", { required: true })}
                      />
                      {errors.login && (
                        <InvalidFeedback text={"Введите Email адрес!"} />
                      )}
                    </div>
                    <div
                      className={`input-form mt-5 ${
                        errors.pass ? "has-error" : ""
                      }`}
                    >
                      <label className="form-label w-full flex flex-col sm:flex-row">
                        Пароль
                      </label>
                      <input
                        type="password"
                        className="intro-x login__input form-control py-3 px-4 block"
                        placeholder="Введите пароль"
                        {...register("pass", { required: true })}
                      />
                      {errors.pass && (
                        <InvalidFeedback text={"Введите пароль!"} />
                      )}
                    </div>
                  </div>

                  <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                    <button
                      className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                      type="submit"
                    >
                      Войти
                    </button>
                    <Link
                      to="/register"
                      className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
                    >
                      Регистрация
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
