import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import DropdownUser from "./DropdownUser"
import { checkIsAuth } from "../../../redux/slices/auth"
import profileImg from "../../../assets/images/profile.png"

const Header = () => {
  const ref = useRef()
  const { user } = useSelector((state) => state.auth)
  const isAuth = useSelector(checkIsAuth)
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen])

  useEffect(() => {
    setIsMenuOpen(false)
  }, [navigate])

  useEffect(() => {
    if (!isAuth) navigate("/login")
  }, [isAuth, navigate])

  return (
    <>
      <div className="top-bar -mx-4 px-4 md:mx-0 md:px-0">
        <nav className="-intro-x mr-auto hidden sm:flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              {user?.role?.type === "administrator" ||
              user?.role?.type === "superadmin"
                ? "Административная панель"
                : "Личный кабинет"}
            </li>
          </ol>
        </nav>

        <div className="intro-x dropdown dropdown-user-info" ref={ref}>
          <div
            className="flex items-center"
            onClick={() => setIsMenuOpen((oldState) => !oldState)}
          >
            <div className="user-nav">
              <span className="font-medium">
                {user?.surname} {user?.name}
              </span>
              <span className="text-slate-500 text-xs">
                {user?.role?.title}
              </span>
            </div>
            <div className="dropdown-toggle w-9 h-9 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
              <img alt="img" src={user?.photo ? user?.photo : profileImg} />
            </div>
          </div>
          {isMenuOpen && <DropdownUser />}
        </div>
      </div>
    </>
  )
}

export default Header
