import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Plus, Search } from "lucide-react"
import {
  getInactiveObjects,
  getCategories
} from "../../../../../redux/slices/object"
import Item from "./Item"
import DeleteModal from "./DeleteModal"

const ObjectList = () => {
  const dispatch = useDispatch()
  const { inactive, categories, status } = useSelector((state) => state.object)
  const [searchValue, setSearchValue] = useState("")
  const [removeObject, setRemoveObject] = useState(null)
  const [currentCategory, setCurrentCategory] = useState(1)

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value)
  }

  const onChangeCategory = (e) => {
    setCurrentCategory(e.target.value)
    setSearchValue("")
  }

  const onClickShowDeleteModal = (object) => {
    setRemoveObject(object)
  }

  useEffect(() => {
    status === "deleted" && dispatch(getInactiveObjects(currentCategory))
  }, [dispatch, status])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  useEffect(() => {
    dispatch(getInactiveObjects(currentCategory))
  }, [dispatch, currentCategory])

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <h2 className="text-lg font-medium mr-auto mt-2">
            Список неактивных объектов
          </h2>
        </div>

        {inactive?.length ? (
          <>
            <div className="intro-y col-span-12 flex flex-wrap xl:flex-nowrap items-center mt-2">
              <div className="flex w-full sm:w-auto">
                <div className="w-48 relative text-slate-500">
                  <input
                    type="text"
                    className="form-control w-48 box pr-10"
                    placeholder="Найти объект..."
                    value={searchValue}
                    onChange={onChangeSearchValue}
                  />
                  <Search className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"></Search>
                </div>
                <select
                  className="w-48 xl:w-auto form-select box ml-2"
                  onChange={onChangeCategory}
                >
                  {categories?.map((cat) => (
                    <option value={cat?.id} key={cat?.id}>
                      {cat?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden xl:block mx-auto text-slate-500">
                Всего объектов: {inactive?.length}
              </div>
              <div className="w-full xl:w-auto flex flex-wrap xl:flex-nowrap items-center gap-y-3 mt-3 xl:mt-0">
                <Link
                  to="/object-create"
                  className="btn btn-primary shadow-md mr-2"
                >
                  <Plus className="w-4 h-4 mr-2"></Plus> Добавить объект
                </Link>
              </div>
            </div>

            <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
              <table className="table table-report -mt-2">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap">Название объекта</th>
                    <th className="text-center whitespace-nowrap">Изменить</th>
                  </tr>
                </thead>
                <tbody>
                  {inactive
                    ?.filter((object) => {
                      return object?.name
                        ?.toLowerCase()
                        .includes(searchValue?.toLowerCase())
                    })
                    ?.map((object, index) => (
                      <Item
                        key={index}
                        object={object}
                        onClickShowDeleteModal={onClickShowDeleteModal}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <div className="alert alert-primary show mb-2 mt-2">
              <div>Объектов пока нет...</div>
            </div>
          </div>
        )}
      </div>

      {removeObject && (
        <DeleteModal
          onClickShowDeleteModal={onClickShowDeleteModal}
          removeObject={removeObject}
        />
      )}
    </>
  )
}

export default ObjectList
