import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

import InvalidFeedback from "../../../components/InvalidFeedback"
import { updateAnswer, deleteQuestion } from "../../../../redux/slices/question"

const View = ({ showView, onChangeTab }) => {
  const dispatch = useDispatch()
  const [answer, setAnswer] = useState(showView?.question.answer.answer)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onChangeAnswer = (event) => {
    setAnswer(event.target.value)
  }

  const onSubmit = async (data) => {
    dispatch(
      updateAnswer({
        id: showView?.question.answer.id,
        answer: data?.answer
      })
    )
    onChangeTab(1)
  }

  const onClickDelete = (id) => {
    dispatch(deleteQuestion({ id, type: "answered" }))
    onChangeTab(1)
  }

  return (
    <>
      <div className="intro-y p-5 box">
        <h2 className="intro-y font-medium text-xl sm:text-lg">
          {showView?.question.question}
        </h2>
        <div className="intro-y text-slate-600 dark:text-slate-500 mt-3 text-xs sm:text-sm">
          {showView?.question.date_create} <span className="mx-1">•</span>
          <span className="text-primary">
            {showView?.question.topic?.title}
          </span>
        </div>

        <div className="intro-y mt-5 pt-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <div className="news__input relative">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12">
                  <div className="">
                    <label
                      htmlFor="update-profile-form-5"
                      className="form-label"
                    >
                      Ответ
                    </label>
                    <textarea
                      id="update-profile-form-5"
                      className="form-control"
                      placeholder="Введите ответ"
                      name="answer"
                      rows="6"
                      {...register("answer", { required: true })}
                      value={answer}
                      onChange={onChangeAnswer}
                    ></textarea>
                    {errors.answer && (
                      <InvalidFeedback text={"Введите ваш ответ"} />
                    )}
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-32 mt-5 mr-5">
                Сохранить
              </button>

              <button
                type="button"
                className="btn btn-outline-danger w-32 mt-5"
                onClick={() => onClickDelete(showView?.question.id)}
              >
                Удалить
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default View
