import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { Plus, Edit3 } from "lucide-react"
import { addCategory } from "../../../../redux/slices/question"
import InvalidFeedback from "../../../components/InvalidFeedback"
import { toast } from "react-toastify"

const CreateCategoryTab = () => {
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const [newImage, setNewImage] = useState(null)

  const handleChangeImage = (e) => {
    setNewImage(e.target.files[0])
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    const newAudioData = new FormData()
    newAudioData.append("topic", data.title)
    newAudioData.append("image", newImage)

    if (newImage) {
      dispatch(addCategory(newAudioData))
      setValue("title", "")
      setNewImage(null)
      inputRef.current.value = null
    } else {
      toast.error("Добавьте иконку")
    }
  }

  return (
    <>
      <div className="intro-y box">
        <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
          <h2 className="font-medium text-base mr-auto">
            Добавление категории
          </h2>
        </div>
        <div className="p-5">
          <div className="flex flex-col-reverse xl:flex-row flex-col">
            <div className="flex-1 mt-6 xl:mt-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-12 2xl:col-span-8">
                    <div className="mb-5">
                      <label className="form-label">Название категории</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите название"
                        name="title"
                        {...register("title", { required: true })}
                      />
                      {errors.title && (
                        <InvalidFeedback text={"Введите название категории"} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-4">
                    <div className="mb-5">
                      <label className="form-label">Изображение</label>

                      <div className="sm:flex items-center">
                        <div className="cursor-pointer relative">
                          {newImage ? (
                            <button
                              type="button"
                              className="btn btn-outline-secondary w-full"
                            >
                              <Edit3 className="lucide lucide-activity w-4 h-4 mr-2" />
                              Изменить иконку
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-outline-secondary w-full"
                            >
                              <Plus className="lucide lucide-activity w-4 h-4 mr-2" />
                              Добавить иконку
                            </button>
                          )}
                          <input
                            type="file"
                            accept="image/*"
                            className="w-full h-full top-0 left-0 absolute opacity-0"
                            ref={inputRef}
                            onChange={handleChangeImage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary w-32 mt-2">
                  Сохранить
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateCategoryTab
