import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Link, Outlet, useNavigate } from "react-router-dom"

import logo from "../../../assets/images/logo/logo-no-bg.svg"
import profileImg from "../../../assets/images/profile.png"
import { checkIsAuth } from "../../../redux/slices/auth"
import DropdownUser from "./DropdownUser"
import MobileMenu from "./MobileMenu"

const UserLayout = () => {
  const ref = useRef()
  const { user } = useSelector((state) => state.auth)
  const isAuth = useSelector(checkIsAuth)
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen])

  useEffect(() => {
    setIsMenuOpen(false)
  }, [navigate])

  useEffect(() => {
    if (!isAuth) navigate("/login")
  }, [isAuth, navigate])

  return (
    <>
      <MobileMenu />

      <div class="top-bar-boxed border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:mx-0 px-3 sm:px-8 md:px-6 mb-10 md:mb-8">
        <div class="h-full flex items-center">
          <Link to="/" class="-intro-x hidden md:flex flex items-center">
            <img alt="ДумРБ" className="w-10" src={logo} />
            <span class="text-white text-lg ml-3 font-semibold">ДУМ РБ</span>
          </Link>

          <nav aria-label="breadcrumb" class="-intro-x h-full mr-auto">
            <ol class="breadcrumb breadcrumb-light">
              <li class="breadcrumb-item active">Личный кабинет</li>
            </ol>
          </nav>

          <div className="intro-x dropdown dropdown-user-info" ref={ref}>
            <div
              className="flex items-center"
              onClick={() => setIsMenuOpen((oldState) => !oldState)}
            >
              <div className="user-nav">
                <span className="text-white font-medium">
                  {user?.surname} {user?.name}
                </span>
                <span className="text-xs text-white/70">
                  {user?.role?.title}
                </span>
              </div>
              <div className="dropdown-toggle w-9 h-9 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
                <img alt="img" src={user?.photo ? user?.photo : profileImg} />
              </div>
            </div>
            {isMenuOpen && <DropdownUser />}
          </div>
        </div>
      </div>

      <div className="content content--top-nav">
        <Outlet />
      </div>
    </>
  )
}

export default UserLayout
