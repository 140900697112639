import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getReaders = createAsyncThunk(
  "readers/getReaders",
  async (page,thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/reader/index.php?page=${page}`,

        {
          headers: {
            "Accept-Language": "ru",
            "Content-Type": "application/json"
          }
        }
      )
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createReader = createAsyncThunk(
  "readers/createReader",
  async (readerData, thunkAPI) => {
    try {
      const { data } = await axios.post("/reader/index.php", readerData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const uploadImage = createAsyncThunk(
  "readers/uploadImage",
  async (readerData, thunkAPI) => {
    try {
      const { data } = await axios.post("/reader/image.php", readerData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteReader = createAsyncThunk(
  "readers/deleteReader",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete("reader/index.php", {
        data: {
          id
        }
      })

      return { id: id, error: data.error }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateReader = createAsyncThunk(
  "readers/updateReader",
  async (readerData, thunkAPI) => {
    try {
      const { data } = await axios.put("/reader/index.php", readerData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  readers: [],
  status: "loading",
  maxPages: 0,
}

const readerSlice = createSlice({
  name: "reader",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Readers
    [getReaders.pending]: (state) => {
      state.readers = []
      state.status = "loading"
    },
    [getReaders.fulfilled]: (state, action) => {
      state.readers = action.payload.result
      state.maxPages = action.payload.max_page
      state.status = "loaded"
    },
    [getReaders.rejected]: (state) => {
      state.readers = []
      state.status = "loading"
    },

    // Create Reader
    [createReader.pending]: (state) => {
      state.status = "loading"
    },
    [createReader.fulfilled]: (state, action) => {
      state.status = "created"
      if (!action.payload.error) {
        toast.success("Чтец успешно создан")
      } else {
        toast.error("Ошибка при создании чтеца!")
      }
    },
    [createReader.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании чтеца!")
    },

    // Delete Reader
    [deleteReader.pending]: (state) => {
      state.status = "loading"
    },
    [deleteReader.fulfilled]: (state, action) => {
      state.status = "loaded"
      state.readers = state.readers?.filter(
        (reader) => reader.id !== action.payload.id
      )

      if (!action.payload.error) {
        toast.success("Чтец успешно удалено")
      } else {
        toast.error("Ошибка при удалении чтеца!")
      }
    },
    [deleteReader.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении чтеца!")
    },

    // Update Reader
    [updateReader.pending]: (state) => {
      state.status = "loading"
    },
    [updateReader.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Чтец успешно обновлен")
      } else {
        toast.error("Ошибка при обновлении чтеца!")
      }
    },
    [updateReader.rejected]: (state) => {
      toast.error("Ошибка при обновлении чтеца!")
    },

    // Upload Image
    [uploadImage.pending]: (state) => {
      state.status = "loading"
    },
    [uploadImage.fulfilled]: (state, action) => {
      state.status = "imageUploaded"
      if (!action.payload.error) {
        toast.success("Картинка успешно загружена")
      } else {
        toast.error("Ошибка при обновлении картинки!")
      }
    },
    [uploadImage.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при обновлении картинки!")
    }
  }
})

export const readerReducer = readerSlice.reducer
