import React from "react"

import { Edit, Trash2, Eye } from "lucide-react"
import { Link } from "react-router-dom"

const Book = ({ book, onClickShowDeleteModal }) => {
  return (
    <>
      <tr className="intro-x">
        <td className="!py-3.5">
          <div className="flex items-center">
            <div className="w-12 h-16 image-fit zoom-in">
              <img
                alt="book"
                className="rounded-lg border-white shadow-md tooltip"
                src={book?.image?.url}
              />
            </div>
            <div className="ml-4 w-120 truncate">
              <Link
                to={`/book-list/${book?.id}`}
                className="font-medium whitespace-nowrap"
              >
                {book?.title}
              </Link>
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {book?.category?.name}
              </div>
            </div>
          </div>
        </td>

        <td className="w-10">
          <a href={book?.url} target="_blank" className="btn btn-primary">
            <Eye className="w-4 h-4"></Eye>
          </a>
        </td>

        <td className="table-report__action w-72">
          <div className="flex justify-center items-center">
            <Link
              to={`/book-list/${book?.id}`}
              className="flex items-center mr-3"
            >
              <Edit className="w-4 h-4 mr-1"></Edit> Изменить
            </Link>

            <button
              className="flex items-center text-danger"
              onClick={() =>
                onClickShowDeleteModal({ id: book?.id, url: book?.url })
              }
            >
              <Trash2 className="w-4 h-4 mr-1"></Trash2> Удалить
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default Book
