import React, { useState } from "react"
import { Plus, Minus } from "lucide-react"

const VideoItem = ({ video, selectVideo, removeVideo }) => {
  const [isSelected, setIsSelected] = useState(false)

  const onClickSelect = (video) => {
    selectVideo(video)
    setIsSelected(true)
  }

  const onClickRemove = (id) => {
    removeVideo(id)
    setIsSelected(false)
  }

  return (
    <>
      <tr className="intro-x">
        <td className="!py-3.5">
          <div className="flex items-center">
            <div className="w-9 h-9 image-fit zoom-in">
              <img
                alt="img"
                className="rounded-lg border-white shadow-md tooltip"
                src={video?.image?.default?.url}
              />
            </div>
            <div className="ml-4 w-144 truncate">
              <div className="font-medium whitespace-nowrap">
                {video?.title}
              </div>
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {video?.description}
              </div>
            </div>
          </div>
        </td>
        <td className="table-report__action">
          <div className="flex justify-center items-center">
            {isSelected ? (
              <button
                className="btn btn-outline-primary btn-sm flex items-center mr-3"
                onClick={() => onClickRemove(video?.id)}
              >
                <Minus className="w-4 h-4 mr-1"></Minus> Убрать
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm flex items-center mr-3"
                onClick={() => onClickSelect(video)}
              >
                <Plus className="w-4 h-4 mr-1"></Plus> Выбрать
              </button>
            )}
          </div>
        </td>
      </tr>
    </>
  )
}

export default VideoItem
