import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChevronDown, Palette, Plus } from "lucide-react"
import { SketchPicker } from "react-color"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import { createSocial, getSocials } from "../../../../redux/slices/about"
import InvalidFeedback from "../../../components/InvalidFeedback"

const SocialsTab = ({ onClickItem }) => {
  const dispatch = useDispatch()
  const { socials } = useSelector((state) => state.about)
  const [newImage, setNewImage] = useState(null)
  const [sketchColor, setSketchColor] = useState("#05A6FF")
  const [showColorPicker, setShowColorPicker] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const handleChangeImage = (e) => {
    setNewImage(e.target.files[0])
  }

  const onSubmit = async (data) => {
    if (newImage) {
      const socialData = new FormData()
      socialData.append("title", data?.title)
      socialData.append("url", data?.url)
      socialData.append("color", sketchColor)
      socialData.append("image", newImage)
      dispatch(createSocial(socialData))
    } else {
      toast.error("Добавьте иконку социальной сети!")
    }
  }

  useEffect(() => {
    dispatch(getSocials())
  }, [dispatch])

  return (
    <>
      <div className="tab-pane p-5 active">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
            <div className="intro-y inbox box">
              <div className="p-5 flex flex-col-reverse sm:flex-row text-slate-500 border-b border-slate-200/60">
                <div className="flex items-center sm:mr-auto">
                  <div className="">
                    Всего социальных сетей: {socials?.length}
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto sm:overflow-x-visible">
                {socials ? (
                  socials?.map((item) => (
                    <div
                      className="intro-y"
                      onClick={() => onClickItem(item)}
                      key={item?.id}
                    >
                      <div className="inbox__item inbox__item--active inline-block sm:block text-slate-600 dark:text-slate-500 bg-slate-100 dark:bg-darkmode-400/70 border-b border-slate-200/60 dark:border-darkmode-400">
                        <div className="flex items-center px-5 py-3">
                          <div className="w-52 flex-none flex items-center mr-5">
                            <div className="w-6 h-6 flex-none image-fit relative">
                              <img
                                alt="Cat"
                                className="rounded-full"
                                src={item?.icon}
                              />
                            </div>
                            <div
                              className="inbox__item--sender truncate ml-3"
                              style={{ color: item?.color }}
                            >
                              {item?.title}
                            </div>
                          </div>
                          <div className="w-64 sm:w-auto truncate">
                            {item?.url}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="p-5">Социальных сетей пока нет...</p>
                )}
              </div>
              <div className="p-1 flex flex-col sm:flex-row items-center text-center sm:text-left text-slate-500"></div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
            <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
              <div className="font-medium flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
                <ChevronDown className="w-4 h-4 mr-2" />
                Добавить социальную сеть
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-x-5 mt-5">
                  <div className="col-span-12 2xl:col-span-12">
                    <div>
                      <label className="form-label">Название</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите название"
                        name="title"
                        {...register("title", { required: true })}
                      />
                      {errors.title && (
                        <InvalidFeedback text={"Введите название"} />
                      )}
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-12">
                    <div className="mt-4">
                      <label className="form-label">Ссылка</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите ссылку"
                        name="url"
                        {...register("url", { required: true })}
                      />
                      {errors.url && (
                        <InvalidFeedback text={"Введите ссылку"} />
                      )}
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mt-4">
                      <label className="form-label">Иконка</label>
                      <div className="cursor-pointer relative">
                        {newImage ? (
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                          >
                            <img
                              className="rounded-full w-5 h-5 mr-2"
                              src={URL.createObjectURL(newImage)}
                            />
                            Изменить иконку
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                          >
                            <Plus className="w-4 h-4 mr-2"></Plus> Добавить
                            иконку
                          </button>
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          className="w-full h-full top-0 left-0 absolute opacity-0"
                          onChange={handleChangeImage}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-6">
                    <div className="mt-4">
                      <label className="form-label">Цвет</label>
                      <div className="cursor-pointer relative flex items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary shadow-md mr-2"
                          onClick={() => setShowColorPicker(!showColorPicker)}
                        >
                          <Palette className="w-5 h-5 mr-2"></Palette> Выбрать
                          цвет
                        </button>
                        <button
                          type="button"
                          className="btn w-8 h-8"
                          style={{ backgroundColor: sketchColor }}
                        ></button>
                      </div>
                      {showColorPicker && (
                        <SketchPicker
                          className="mt-2"
                          color={sketchColor}
                          onChange={(e) => setSketchColor(e.hex)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-12">
                    <button type="submit" className="btn btn-primary w-32 mt-5">
                      Добавить
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialsTab
