import React from "react"
import mainBanner from "../../../../assets/images/dumrb-banner.png"

const Banner = () => {
  return (
    <>
      <div className="col-span-12 lg:col-span-8 mt-8">
        <div className="box p-8 relative overflow-hidden intro-y flex items-center">
          <img src={mainBanner} alt="img" />
        </div>
      </div>
    </>
  )
}

export default Banner
