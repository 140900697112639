import React from "react"
import { Edit, Trash2 } from "lucide-react"
import { Link } from "react-router-dom"
import noImg from "../../../../../assets/images/no-image.png"

const Object = ({ object, onClickShowDeleteModal }) => {
  return (
    <>
      <tr className="intro-x">
        <td className="!py-3.5">
          <div className="flex items-center">
            <div className="w-9 h-9 image-fit zoom-in">
              <img
                alt=""
                className="rounded-lg border-white shadow-md tooltip"
                src={
                  object?.main_image
                    ? object?.main_image
                    : object?.photo?.[0]
                    ? object?.photo?.[0]
                    : noImg
                }
              />
            </div>
            <div className="ml-4 w-120 truncate">
              <Link
                to={`/object-list-inactive/${object?.id}`}
                className="font-medium whitespace-nowrap"
              >
                {object?.name}
              </Link>
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {object?.category?.name}
              </div>
            </div>
          </div>
        </td>

        <td className="table-report__action w-72">
          <div className="flex justify-center items-center">
            <Link
              to={`/object-list-inactive/${object?.id}`}
              className="flex items-center mr-3"
            >
              <Edit className="w-4 h-4 mr-1"></Edit> Изменить
            </Link>

            <button
              className="flex items-center text-danger"
              onClick={() => onClickShowDeleteModal(object?.id)}
            >
              <Trash2 className="w-4 h-4 mr-1"></Trash2> Удалить
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default Object
