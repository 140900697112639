import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import Select from "react-select"

import axios from "../../../../utils/axios"
import InvalidFeedback from "../../../components/InvalidFeedback"
import {
  deleteVideo,
  getCategories,
  updateVideo
} from "../../../../redux/slices/video"
import { languages } from "../../../../utils/languages"

const VideoView = () => {
  const [videoData, setVideoData] = useState(null)
  const { categories } = useSelector((state) => state.video)
  const params = useParams()
  const dispatch = useDispatch()
  const [currentLang, setCurrentLang] = useState("ru")

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const fetchVideo = useCallback(
    async (lang) => {
      const { data } = await axios.get(`/video/index.php?id=${params.id}`, {
        headers: { "Accept-Language": lang }
      })

      setVideoData({
        id: data.result[0]?.id,
        title: data.result[0]?.title,
        author: data.result[0]?.author,
        description: data.result[0]?.description,
        url: data.result[0]?.url,
        image: data.result[0]?.image,
        category: data.result[0]?.category?.[0]?.id,
        lang: lang
      })
    },
    [params.id]
  )

  const onChangeLang = (e) => {
    setCurrentLang(e.value)
  }

  const onSubmit = async (data) => {
    dispatch(updateVideo({ ...data, category: parseInt(data?.category) }))
  }

  useEffect(() => {
    reset(videoData)
  }, [videoData, currentLang])

  useEffect(() => {
    fetchVideo(currentLang)
    reset(videoData)
  }, [fetchVideo, currentLang])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className="intro-y flex items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Изменить видео</h2>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y box lg:mt-5">
            <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
              <h2 className="font-medium text-base mr-auto">
                Редактирование данных
              </h2>
              <Select
                placeholder="Выберите язык"
                className="basic-single-select"
                value={languages?.filter((obj) => obj.value === currentLang)}
                options={languages}
                onChange={onChangeLang}
              />
            </div>
            <div className="p-5">
              <div className="flex flex-col-reverse xl:flex-row flex-col">
                <div className="flex-1 mt-6 xl:mt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 gap-x-5">
                      <div className="col-span-12 2xl:col-span-12">
                        <div className="mb-5">
                          <label className="form-label">Название</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Введите название"
                            name="title"
                            {...register("title", { required: true })}
                          />
                          {errors.title && (
                            <InvalidFeedback text={"Введите название"} />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Категория</label>
                          <select
                            className="tom-select w-full"
                            {...register("category", { required: true })}
                          >
                            {categories?.map((cat) => (
                              <option value={cat.id} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                          {errors.category && (
                            <InvalidFeedback text={"Выберите категорию"} />
                          )}
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-6">
                        <div className="mb-5">
                          <label className="form-label">Автор</label>
                          <input
                            name="author"
                            type="text"
                            {...register("author", { required: true })}
                            className="form-control"
                            placeholder="Введите автора"
                          />
                          {errors.author && (
                            <InvalidFeedback text={"Введите имя автора"} />
                          )}
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="mb-5">
                          <label className="form-label">Описание</label>
                          <textarea
                            className="form-control"
                            placeholder="Введите описание"
                            name="description"
                            {...register("description")}
                          ></textarea>
                          {errors.description && (
                            <InvalidFeedback text={"Введите описание"} />
                          )}
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-32 mt-2">
                      Сохранить
                    </button>

                    <button
                      className="btn btn-outline-danger w-32 ml-5"
                      type="button"
                      onClick={() => dispatch(deleteVideo(params.id))}
                    >
                      Удалить
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoView
