import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { addQuestionAnswer } from "../../../../redux/slices/question"
import InvalidFeedback from "../../../components/InvalidFeedback"

const CreateTab = ({ onChangeTab }) => {
  const { categories } = useSelector((state) => state.question)
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    dispatch(
      addQuestionAnswer({
        question: data?.question,
        answer: data?.answer,
        topic: parseInt(data?.topic, 10)
      })
    )
    onChangeTab(1)
  }

  return (
    <>
      <div className="intro-y box">
        <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
          <h2 className="font-medium text-base mr-auto">
            Добавить вопрос/ответ
          </h2>
        </div>
        <div className="p-5">
          <div className="flex flex-col-reverse xl:flex-row flex-col">
            <div className="flex-1 mt-6 xl:mt-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-12 2xl:col-span-8">
                    <div className="mb-5">
                      <label className="form-label">Вопрос</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите вопрос"
                        name="question"
                        {...register("question", { required: true })}
                      />
                      {errors.question && (
                        <InvalidFeedback text={"Введите вопрос"} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-4">
                    <div className="mb-5">
                      <label
                        htmlFor="update-profile-form-2"
                        className="form-label"
                      >
                        Категория
                      </label>
                      <select
                        className="tom-select w-full"
                        {...register("topic", { required: true })}
                      >
                        {categories?.map((cat) => (
                          <option value={cat.id} key={cat.id}>
                            {cat.title}
                          </option>
                        ))}
                      </select>
                      {errors.topic && (
                        <InvalidFeedback text={"Выберите категорию"} />
                      )}
                    </div>
                  </div>

                  <div className="col-span-12">
                    <div className="mb-5">
                      <label className="form-label">Ответ</label>
                      <textarea
                        className="form-control"
                        placeholder="Введите ответ"
                        name="answer"
                        rows="3"
                        {...register("answer", { required: true })}
                      ></textarea>
                      {errors.answer && (
                        <InvalidFeedback text={"Введите ваш ответ"} />
                      )}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary w-32 mt-2">
                  Сохранить
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateTab
