import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { LogOut, User } from "lucide-react"
import { useDispatch } from "react-redux"
import { logout } from "../../../redux/slices/auth"

const DropdownUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onClickLogout = () => {
    dispatch(logout())
    navigate("/login")
  }

  return (
    <>
      <div className="dropdown-menu w-56 dropdown-user show">
        <ul className="dropdown-content bg-primary text-white">
          <li>
            <Link to="/profile" className="dropdown-item hover:bg-white/5">
              <User className="w-4 h-4 mr-2"></User> Профиль
            </Link>
          </li>

          <li>
            <hr className="dropdown-divider border-white/[0.08]" />
          </li>
          <li>
            <button
              className="dropdown-item hover:bg-white/5 w-full"
              onClick={() => onClickLogout()}
            >
              <LogOut className="w-4 h-4 mr-2"></LogOut> Выход
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default DropdownUser
