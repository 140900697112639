import {
  Book,
  Library,
  Plus,
  LayoutList,
  List,
  ListMusic,
  Music4,
  Building2,
  Mic,
  Users,
  HelpCircle,
  Video,
  ListVideo,
  MessageCircle,
  Home
} from "lucide-react"

export const publicMenu = [
  {
    title: "Главная",
    type: "/",
    path: "/",
    icon: <Home />,
    sub: null
  }
]

export const privateMenu = [
  {
    title: "Главная",
    type: "/",
    path: "/",
    icon: <Home />,
    sub: null
  },
  {
    title: "Книги",
    type: "book",
    icon: <Book />,
    sub: [
      {
        title: "Список книг",
        path: "/book-list",
        type: "book",
        icon: <Library />
      },
      {
        title: "Создать книгу",
        path: "/book-create",
        type: "book",
        icon: <Plus />
      },
      {
        title: "Категории",
        path: "/book-categories",
        type: "book",
        icon: <List />
      }
    ]
  },
  {
    title: "Аудио",
    type: "audio",
    icon: <Music4 />,
    sub: [
      {
        title: "Список аудио",
        path: "/audio-list",
        type: "audio",
        icon: <ListMusic />
      },
      {
        title: "Создать аудио",
        path: "/audio-create",
        type: "audio",
        icon: <Plus />
      },
      {
        title: "Категории",
        path: "/audio-categories",
        type: "audio",
        icon: <List />
      }
    ]
  },
  {
    title: "Объекты",
    type: "object",
    icon: <Building2 />,
    sub: [
      {
        title: "Активные",
        path: "/object-list-active",
        type: "object",
        icon: <LayoutList />
      },
      {
        title: "Неактивные",
        path: "/object-list-inactive",
        type: "object",
        icon: <LayoutList />
      },
      {
        title: "На модерации",
        path: "/object-list-moderation",
        type: "object",
        icon: <LayoutList />
      },
      {
        title: "Создать объект",
        path: "/object-create",
        type: "object",
        icon: <Plus />
      }
    ]
  },
  {
    title: "Чтецы",
    type: "reader",
    icon: <Mic />,
    sub: [
      {
        title: "Список чтецов",
        path: "/reader-list",
        type: "reader",
        icon: <Users />
      },
      {
        title: "Создать чтеца",
        path: "/reader-create",
        type: "reader",
        icon: <Plus />
      }
    ]
  },
  {
    title: "Видео",
    type: "video",
    icon: <Video />,
    sub: [
      {
        title: "Категории",
        path: "/video-categories",
        type: "video",
        icon: <ListVideo />
      },
      {
        title: "Создать видео",
        path: "/video-create",
        type: "video",
        icon: <Plus />
      }
    ]
  },
  {
    title: "Вопросы",
    type: "question",
    path: "/question-list",
    icon: <HelpCircle />,
    sub: null
  },
  {
    title: "Сообщения",
    type: "feedback",
    path: "/feedback",
    icon: <MessageCircle />,
    sub: null
  }
]
