import React from "react"
import { Edit, Trash2 } from "lucide-react"

const Category = ({
  category,
  onClickShowDeleteModal,
  onClickShowEditModal
}) => {
  return (
    <>
      <tr>
        <td className="!py-3.5">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="font-medium whitespace-nowrap">
                {category?.name}
              </div>
            </div>
          </div>
        </td>

        <td className="table-report__action w-72">
          <div className="flex justify-center items-center">
            <button
              className="flex items-center mr-3"
              onClick={() => onClickShowEditModal(category)}
            >
              <Edit className="w-4 h-4 mr-1"></Edit> Изменить
            </button>

            <button
              className="flex items-center text-danger"
              onClick={() => onClickShowDeleteModal(category?.id)}
            >
              <Trash2 className="w-4 h-4 mr-1"></Trash2> Удалить
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default Category
