import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import Select from "react-select"

import axios from "../../../../utils/axios"
import InvalidFeedback from "../../../components/InvalidFeedback"
import { createReader } from "../../../../redux/slices/reader"
import { Plus, Trash2 } from "lucide-react"

const ReaderCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [regions, setRegions] = useState([])
  const [cities, setCities] = useState([])
  const [currentRegion, setCurrentRegion] = useState(1)
  const [currentCity, setCurrentCity] = useState(1)
  const { status } = useSelector((state) => state.reader)
  const [properties, setProperties] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([])
  const [socials, setSocials] = useState([])
  const [currentSocials, setCurrentSocials] = useState([])

  const [newSocialType, setNewSocialType] = useState(1)
  const [newSocialUrl, setNewSocialUrl] = useState("")

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const fetchProperties = useCallback(async () => {
    const { data } = await axios.get("/reader/listProperty.php", {
      headers: {
        "Accept-Language": "ru"
      }
    })

    if (!data?.error) {
      const changedObjects = data?.result?.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      setProperties(changedObjects)
    }
  }, [])

  const fetchSocials = useCallback(async () => {
    const { data } = await axios.get("/network/type.php")

    if (!data?.error) {
      const changedSocials = data?.result?.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      setSocials(changedSocials)
    }
  }, [])

  const fetchRegions = useCallback(async () => {
    const { data } = await axios.get("/city/regions.php", {
      headers: {
        "Accept-Language": "ru"
      }
    })
    setRegions(data?.result)
  }, [])

  const fetchCities = useCallback(async (currentRegion) => {
    const { data } = await axios.get(`/city/regions.php?id=${currentRegion}`, {
      headers: {
        "Accept-Language": "ru"
      }
    })
    setCities(data?.result?.[0].cities)
  }, [])

  const onChangeRegion = (e) => {
    setCurrentRegion(e.target.value)
  }

  const onChangeCity = (e) => {
    setCurrentCity(e.target.value)
  }

  const onChangeProperty = (selectedOption) => {
    setSelectedProperties(selectedOption)
  }

  const onChangeSocial = (e) => {
    setNewSocialType(e.value)
  }

  const getLinkText = () => {
    return newSocialType === 1
      ? "https://t.me/"
      : newSocialType === 2
      ? "https://wa.me/"
      : newSocialType === 3
      ? "https://instagram.com/"
      : newSocialType === 4
      ? "https://facebook.com/"
      : newSocialType === 5
      ? "https://vk.com/"
      : ""
  }

  const getSocialData = (id) => {
    const socialItem = socials?.filter((obj) => obj.value === id)
    return socialItem?.[0]
  }

  const onClickAddSocial = () => {
    if (newSocialUrl) {
      const newSocialData = {
        type: newSocialType,
        url: newSocialUrl
      }
      setCurrentSocials((prev) => [...prev, newSocialData])
      setNewSocialUrl("")
    }
  }

  const onSubmit = async (data) => {
    const readerProps = selectedProperties?.map((prop) => {
      return prop.value
    })

    const readerData = {
      surname: data?.surname,
      name: data?.name,
      description: data?.description,
      phone: data?.phone,
      city: currentCity,
      properties: readerProps,
      social_networks: []
    }

    dispatch(createReader(readerData))
  }

  useEffect(() => {
    status === "created" && navigate("/reader-list")
  }, [navigate, status])

  useEffect(() => {
    fetchProperties()
  }, [fetchProperties])

  useEffect(() => {
    fetchSocials()
  }, [fetchSocials])

  useEffect(() => {
    fetchRegions()
  }, [fetchRegions])

  useEffect(() => {
    fetchCities(currentRegion)
  }, [fetchCities, currentRegion])

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y box lg:mt-5">
            <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400 mt-8">
              <h2 className="font-medium text-base mr-auto">
                Добавить нового чтеца
              </h2>
            </div>
            <div className="p-5">
              <div className="flex flex-col-reverse xl:flex-row flex-col">
                <div className="flex-1 mt-6 xl:mt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 gap-x-5">
                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Фамилия</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Введите фамилию"
                            name="surname"
                            {...register("surname", { required: true })}
                          />
                          {errors.surname && (
                            <InvalidFeedback text={"Введите фамилию"} />
                          )}
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Имя</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Введите имя"
                            name="name"
                            {...register("name", { required: true })}
                          />
                          {errors.name && (
                            <InvalidFeedback text={"Введите имя"} />
                          )}
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Телефон</label>
                          <InputMask
                            mask="+79999999999"
                            name="phone"
                            type="text"
                            {...register("phone")}
                            className="form-control"
                            placeholder="Введите телефон"
                          ></InputMask>
                          {errors.phone && (
                            <InvalidFeedback text={"Введите телефон"} />
                          )}
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Регион</label>
                          <select
                            className="tom-select w-full"
                            {...register("region")}
                            onChange={onChangeRegion}
                          >
                            {regions?.map((cat) => (
                              <option value={cat.id} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-3">
                        <div className="mb-5">
                          <label className="form-label">Город</label>
                          <select
                            className="tom-select w-full"
                            {...register("city")}
                            onChange={onChangeCity}
                          >
                            {cities?.map((cat) => (
                              <option value={cat.id} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-5">
                        <div className="mb-5">
                          <label className="form-label">Свойства</label>
                          <Select
                            onChange={onChangeProperty}
                            isMulti
                            name="colors"
                            options={properties}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Выберите свойства"
                          />
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-5">
                          <label className="form-label">Описание</label>
                          <textarea
                            className="form-control"
                            placeholder="Введите описание"
                            name="description"
                            {...register("description")}
                          ></textarea>
                          {errors.description && (
                            <InvalidFeedback text={"Введите описание"} />
                          )}
                        </div>
                      </div>

                      {socials?.length > 0 && (
                        <div className="col-span-12">
                          <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                            <div className="text-base flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
                              Социальные сети
                            </div>
                            <div className="mt-5">
                              <div className="form-inline items-start flex-col xl:flex-row mt-2 pt-2 first:mt-0 first:pt-0">
                                <div className="w-full mt-3 xl:mt-0 flex-1">
                                  <div className="grid grid-cols-12 gap-5 mb-5">
                                    <div className="col-span-12 lg:col-span-3">
                                      <Select
                                        placeholder="Выберите соц.сеть"
                                        className="basic-single-select"
                                        value={socials?.filter(
                                          (obj) => obj.value === newSocialType
                                        )}
                                        options={socials}
                                        onChange={onChangeSocial}
                                      />
                                    </div>
                                    <div className="col-span-12 lg:col-span-6">
                                      <div className="mt-2 sm:mt-0">
                                        <div className="input-group">
                                          {getLinkText() && (
                                            <div class="input-group-text">
                                              {getLinkText()}
                                            </div>
                                          )}

                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Введите ссылку"
                                            onChange={(e) =>
                                              setNewSocialUrl(e.target.value)
                                            }
                                            value={newSocialUrl}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-span-12 lg:col-span-3">
                                      <div className="mt-2 sm:mt-0">
                                        <button
                                          className="btn btn-outline-primary border-dashed"
                                          type="button"
                                          onClick={onClickAddSocial}
                                        >
                                          <Plus className="lucide lucide-plus w-4 h-4 mr-2" />
                                          Добавить
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {currentSocials?.length > 0 && (
                                    <div className="overflow-x-auto mt-5">
                                      <table className="table border">
                                        <thead>
                                          <tr>
                                            <th className="bg-slate-50 dark:bg-darkmode-800">
                                              Название
                                            </th>
                                            <th className="!px-2 bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">
                                              Ссылка на социальную сеть
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {currentSocials?.map((social, i) => (
                                            <tr key={i}>
                                              <td className="whitespace-nowrap">
                                                {
                                                  getSocialData(social?.type)
                                                    ?.label
                                                }
                                              </td>
                                              <td className="!px-2 text-slate-500">
                                                {social?.url}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <button type="submit" className="btn btn-primary w-32 mt-5">
                      Сохранить
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReaderCreate
