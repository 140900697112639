import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Select from 'react-select';

import axios from '../../../../utils/axios';
import uploadImg from '../../../../assets/images/upload.png';
import InvalidFeedback from '../../../components/InvalidFeedback';
import { updateReader, uploadImage } from '../../../../redux/slices/reader';
import { Plus, Trash2 } from 'lucide-react';
import { languages } from '../../../../utils/languages';

const ReaderView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [newImage, setNewImage] = useState(null);
  const [readerData, setReaderData] = useState(null);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentRegion, setCurrentRegion] = useState(1);
  const [currentCity, setCurrentCity] = useState(1);
  const { status } = useSelector((state) => state.reader);
  const [properties, setProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);

  const [socials, setSocials] = useState([]);
  const [currentSocials, setCurrentSocials] = useState([]);

  const [newSocialType, setNewSocialType] = useState(1);
  const [newSocialUrl, setNewSocialUrl] = useState('');

  const [currentLang, setCurrentLang] = useState('ru');

  const [phone, setPhone] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const fetchReader = useCallback(
    async (lang) => {
      const { data } = await axios.get(`/reader/index.php?id=${params.id}`, {
        headers: {
          'Accept-Language': lang,
        },
      });

      if (!data.error) {
        setReaderData({
          id: data.result[0]?.id,
          surname: data.result[0]?.surname,
          name: data.result[0]?.name,
          phone: data.result[0]?.phone,
          social_networks: data.result[0]?.social_networks,
          properties: data.result[0]?.properties,
          description: data.result[0]?.description,
          image: data.result[0]?.image,
          city: data.result[0]?.city?.id,
          region: data.result[0]?.city?.region?.id,
        });

        data.result[0]?.phone ? setPhone(data.result[0]?.phone) : setPhone('');
      }
    },
    [params.id],
  );

  const fetchProperties = useCallback(async () => {
    const { data } = await axios.get('/reader/listProperty.php', {
      headers: {
        'Accept-Language': 'ru',
      },
    });

    if (!data?.error) {
      const changedObjects = data?.result?.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setProperties(changedObjects);
    }
  }, []);

  const fetchSocials = useCallback(async () => {
    const { data } = await axios.get('/network/type.php');

    if (!data?.error) {
      const changedSocials = data?.result?.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSocials(changedSocials);
    }
  }, []);

  const fetchRegions = useCallback(async () => {
    const { data } = await axios.get('/city/regions.php', {
      headers: {
        'Accept-Language': 'ru',
      },
    });
    setRegions(data?.result);
  }, []);

  const fetchCities = useCallback(async (currentRegion) => {
    const { data } = await axios.get(`/city/regions.php?id=${currentRegion}`);
    setCities(data?.result?.[0]?.cities);
  }, []);

  const getLinkText = () => {
    return newSocialType === 1
      ? 'https://t.me/'
      : newSocialType === 2
      ? 'https://wa.me/'
      : newSocialType === 3
      ? 'https://instagram.com/'
      : newSocialType === 4
      ? 'https://facebook.com/'
      : newSocialType === 5
      ? 'https://vk.com/'
      : '';
  };

  const handleChangeImage = (e) => {
    setNewImage(e.target.files[0]);
  };

  const onChangeRegion = (e) => {
    setCurrentRegion(e.target.value);
  };

  const onChangeCity = (e) => {
    setCurrentCity(e.target.value);
  };

  const onChangeProperty = (selectedOption) => {
    setSelectedProperties(selectedOption);
  };

  const onChangeSocial = (e) => {
    setNewSocialType(e.value);
  };

  const getSocialData = (id) => {
    const socialItem = socials?.filter((obj) => obj.value === id);
    return socialItem?.[0];
  };

  const onClickDeleteSocial = (id) => {
    const filteredSocials = currentSocials?.filter((obj) => obj.type !== id);
    setCurrentSocials(filteredSocials);
  };

  const onClickAddSocial = () => {
    if (newSocialUrl) {
      const newSocialData = {
        type: newSocialType,
        url: newSocialUrl,
      };
      setCurrentSocials((prev) => [...prev, newSocialData]);
      setNewSocialUrl('');
    }
  };

  const onClickSaveImage = () => {
    const newAudioData = new FormData();
    newAudioData.append('id', params.id);
    newAudioData.append('image', newImage);
    dispatch(uploadImage(newAudioData));
  };

  const onChangeLang = (e) => {
    setCurrentLang(e.value);
  };

  const onSubmit = async (data) => {
    const readerProps = selectedProperties?.map((prop) => {
      return prop.value;
    });

    const readerData = {
      id: params.id,
      surname: data?.surname,
      name: data?.name,
      description: data?.description,
      phone: phone,
      city: currentCity,
      properties: readerProps,
      social_networks: currentSocials,
      lang: currentLang,
    };
    dispatch(updateReader(readerData));
  };

  useEffect(() => {
    fetchReader(currentLang);
    reset(readerData);
  }, [fetchReader, currentLang]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  useEffect(() => {
    fetchSocials();
  }, [fetchSocials]);

  useEffect(() => {
    fetchRegions();
  }, [fetchRegions]);

  useEffect(() => {
    fetchCities(currentRegion);
  }, [fetchCities, currentRegion]);

  useEffect(() => {
    setCurrentRegion(readerData?.region);
    setCurrentCity(readerData?.city);

    if (readerData?.properties) {
      const readerProperties = readerData?.properties?.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSelectedProperties(readerProperties);
    }

    if (readerData?.social_networks) {
      const readerSocials = readerData?.social_networks?.map(({ type, url }) => ({
        type: type?.id,
        url: url,
      }));
      setCurrentSocials(readerSocials);
    }

    // if (readerData?.phone) {
    //   setValue('phone', readerData?.phone);
    // } else {
    //   setValue('phone', '');
    // }

    reset(readerData);
  }, [readerData]);

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y box lg:mt-5">
            <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400 mt-8">
              <h2 className="font-medium text-base mr-auto">Редактирование данных чтеца</h2>
              <Select
                placeholder="Выберите язык"
                className="basic-single-select"
                value={languages?.filter((obj) => obj.value === currentLang)}
                options={languages}
                onChange={onChangeLang}
              />
            </div>
            <div className="p-5">
              <div className="flex flex-col-reverse xl:flex-row flex-col">
                <div className="flex-1 mt-6 xl:mt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 gap-x-5">
                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Фамилия</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Введите фамилию"
                            name="surname"
                            {...register('surname', { required: true })}
                          />
                          {errors.surname && <InvalidFeedback text={'Введите фамилию'} />}
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Имя</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Введите имя"
                            name="name"
                            {...register('name', { required: true })}
                          />
                          {errors.name && <InvalidFeedback text={'Введите имя'} />}
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Телефон</label>
                          <InputMask
                            mask="+79999999999"
                            // name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                            // {...register('phone')}
                            className="form-control"
                            placeholder="Введите телефон"></InputMask>
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Регион</label>
                          <select
                            className="tom-select w-full"
                            {...register('region')}
                            value={currentRegion}
                            onChange={onChangeRegion}>
                            {regions?.map((cat) => (
                              <option value={cat.id} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-4">
                        <div className="mb-5">
                          <label className="form-label">Город</label>
                          <select
                            className="tom-select w-full"
                            {...register('city')}
                            value={currentCity}
                            onChange={onChangeCity}>
                            {cities?.map((cat) => (
                              <option value={cat.id} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-span-12 2xl:col-span-8">
                        <div className="mb-5">
                          <label className="form-label">Свойства</label>
                          {selectedProperties.length > 0 && (
                            <Select
                              defaultValue={selectedProperties}
                              onChange={onChangeProperty}
                              isMulti
                              name="colors"
                              options={properties}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Выберите свойства"
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-5">
                          <label className="form-label">Описание</label>
                          <textarea
                            className="form-control"
                            placeholder="Введите описание"
                            name="description"
                            rows={4}
                            {...register('description')}></textarea>
                          {errors.description && <InvalidFeedback text={'Введите описание'} />}
                        </div>
                      </div>

                      {socials?.length > 0 && (
                        <div className="col-span-12">
                          <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                            <div className="text-base flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
                              Социальные сети
                            </div>
                            <div className="mt-5">
                              <div className="form-inline items-start flex-col xl:flex-row mt-2 pt-2 first:mt-0 first:pt-0">
                                <div className="w-full mt-3 xl:mt-0 flex-1">
                                  <div className="grid grid-cols-12 gap-5 mb-5">
                                    <div className="col-span-12 lg:col-span-3">
                                      <Select
                                        placeholder="Выберите соц.сеть"
                                        className="basic-single-select"
                                        value={socials?.filter(
                                          (obj) => obj.value === newSocialType,
                                        )}
                                        options={socials}
                                        onChange={onChangeSocial}
                                      />
                                    </div>
                                    <div className="col-span-12 lg:col-span-6">
                                      <div className="mt-2 sm:mt-0">
                                        <div className="input-group">
                                          {getLinkText() && (
                                            <div class="input-group-text">{getLinkText()}</div>
                                          )}
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Введите ссылку"
                                            onChange={(e) => setNewSocialUrl(e.target.value)}
                                            value={newSocialUrl}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-span-12 lg:col-span-3">
                                      <div className="mt-2 sm:mt-0">
                                        <button
                                          className="btn btn-outline-primary border-dashed"
                                          type="button"
                                          onClick={onClickAddSocial}>
                                          <Plus className="lucide lucide-plus w-4 h-4 mr-2" />
                                          Добавить
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {currentSocials?.length > 0 && (
                                    <div className="overflow-x-auto mt-5">
                                      <table className="table border">
                                        <thead>
                                          <tr>
                                            <th className="bg-slate-50 dark:bg-darkmode-800">
                                              Название
                                            </th>
                                            <th className="!px-2 bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap">
                                              Ссылка на социальную сеть
                                            </th>
                                            <th className="bg-slate-50 dark:bg-darkmode-800"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {currentSocials?.map((social, i) => (
                                            <tr key={i}>
                                              <td className="whitespace-nowrap">
                                                {getSocialData(social?.type)?.label}
                                              </td>
                                              <td className="!px-2 text-slate-500">
                                                {social?.url}
                                              </td>
                                              <td className="!px-2 text-slate-500">
                                                <button
                                                  type="button"
                                                  onClick={() => onClickDeleteSocial(social?.type)}>
                                                  <Trash2 className="lucide lucide-trash-2 w-4 h-4" />
                                                </button>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <button type="submit" className="btn btn-primary w-32 mt-5">
                      Сохранить
                    </button>
                  </form>
                </div>
                <div className="w-52 mx-auto xl:mr-0 xl:ml-6">
                  <div className="border-2 border-dashed shadow-sm border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                    {newImage ? (
                      <div className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                        <img
                          className="rounded-md mx-auto"
                          alt="image"
                          src={URL.createObjectURL(newImage)}
                        />
                      </div>
                    ) : (
                      <div className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                        {readerData?.image ? (
                          <img className="rounded-md mx-auto" alt="img" src={readerData?.image} />
                        ) : (
                          <img className="rounded-md mx-auto" alt="image" src={uploadImg} />
                        )}
                      </div>
                    )}

                    <div className="mx-auto cursor-pointer relative mt-5">
                      <button type="button" className="btn btn-primary w-full">
                        {readerData?.image || newImage ? 'Изменить фото' : 'Добавить фото'}
                      </button>

                      <input
                        type="file"
                        className="w-full h-full top-0 left-0 absolute opacity-0"
                        onChange={handleChangeImage}
                      />
                    </div>
                    {status !== 'imageUploaded' && newImage && (
                      <button
                        type="button"
                        className="btn btn-outline-primary w-full mt-2"
                        onClick={onClickSaveImage}>
                        Сохранить
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReaderView;
