import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getMessages = createAsyncThunk(
  "messages/getMessages",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/questions_admin/feedback.php")
      return data.result
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteMessage = createAsyncThunk(
  "messages/deleteMessage",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/questions_admin/delete.php",
        { id },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      return { id, error: data.error }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const answerMessage = createAsyncThunk(
  "messages/answerMessage",
  async (messageData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/questions_admin/feedback.php",
        messageData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  messages: [],
  status: "loading"
}

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Messages
    [getMessages.pending]: (state) => {
      state.messages = []
      state.status = "loading"
    },
    [getMessages.fulfilled]: (state, action) => {
      state.messages = action.payload
      state.status = "loaded"
    },
    [getMessages.rejected]: (state) => {
      state.messages = []
      state.status = "loading"
    },

    // Delete Message
    [deleteMessage.pending]: (state) => {
      state.status = "loading"
    },
    [deleteMessage.fulfilled]: (state, action) => {
      state.status = "loaded"

      if (!action.payload.error) {
        toast.success("Сообщение успешно удалено")
        state.messages = state.messages?.filter(
          (message) => message.id !== action.payload.id
        )
      } else {
        toast.error("Ошибка при удалении сообщения!")
      }
    },
    [deleteMessage.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при удалении аудио!")
    },

    // Answer Message
    [answerMessage.pending]: (state) => {
      state.status = "loading"
    },
    [answerMessage.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Ответ успешно отправлен")
        state.messages = state.messages?.filter(
          (message) => message.id !== action.payload.result
        )
      } else {
        toast.error("Ошибка при отправке ответа!")
      }
    },
    [answerMessage.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при отправке ответа!")
    }
  }
})

export const messageReducer = messageSlice.reducer
