import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  getAnswered,
  getCategories,
  getUnanswered
} from "../../../redux/slices/question"
import Content from "./Content"
import Sidebar from "./Sidebar"

const QuestionList = () => {
  const dispatch = useDispatch()
  const { unanswered, answered, categories, status } = useSelector(
    (state) => state.question
  )
  const [activeTab, setActiveTab] = useState(1)
  const [showView, setShowView] = useState(null)

  const onChangeTab = (tab) => {
    setShowView(null)
    setActiveTab(tab)
  }

  const onClickItem = (data) => {
    setShowView(data)
  }

  const updateArrays = () => {
    dispatch(getUnanswered())
    dispatch(getAnswered())
  }

  useEffect(() => {
    status === "questionCreated"
      ? dispatch(getAnswered())
      : status === "categoryCreated"
      ? dispatch(getCategories())
      : status === "answerAdded" && updateArrays()
  }, [dispatch, status])

  useEffect(() => {
    dispatch(getUnanswered())

    dispatch(getAnswered())

    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-8">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <h2 className="text-lg font-medium mr-auto mt-2">Вопросы и ответы</h2>
        </div>
        <Sidebar
          categories={categories}
          onChangeTab={onChangeTab}
          activeTab={activeTab}
        />
        <Content
          answered={answered}
          unanswered={unanswered}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
          showView={showView}
          onClickItem={onClickItem}
        />
      </div>
    </>
  )
}

export default QuestionList
