import React from "react"
import { X } from "lucide-react"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"

import InvalidFeedback from "../../components/InvalidFeedback"
import { answerMessage, deleteMessage } from "../../../redux/slices/message"

const Modal = ({ onClickItem, showModal }) => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    const messageData = new FormData()
    messageData.append("id", showModal?.id)
    messageData.append("answer", data?.answer)
    dispatch(answerMessage(messageData))
    onClickItem(null)
  }

  const onClickDelete = (id) => {
    dispatch(deleteMessage(id))
    onClickItem(null)
  }

  return (
    <div className="modal overflow-y-auto show">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="font-medium text-md mr-auto truncate">
              {showModal?.title}
            </h2>
            <button data-tw-dismiss="modal" onClick={() => onClickItem(null)}>
              <X className="w-8 h-8 text-slate-400" />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <div className="col-span-12 sm:col-span-12">
                <div className="text-slate-600 mb-5">
                  {showModal?.description}
                </div>

                <label className="form-label">Ответ</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Введите ваш ответ"
                  {...register("answer", { required: true })}
                ></textarea>
                {errors.answer && <InvalidFeedback text={"Введите описание"} />}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger w-24 mr-1"
                onClick={() => onClickDelete(showModal?.id)}
              >
                Удалить
              </button>
              <button type="submit" className="btn btn-primary w-24">
                Ответить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Modal
