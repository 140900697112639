import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ChevronDown } from "lucide-react"
import { useForm } from "react-hook-form"

import {
  updateObject,
  updateSchedule
} from "../../../../../redux/slices/object"
import InvalidFeedback from "../../../../components/InvalidFeedback"
import axios from "../../../../../utils/axios"

const GeneralTab = ({ objectData }) => {
  const dispatch = useDispatch()
  const [schedule, setSchedule] = useState({})

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data) => {
    const scheduleData = {
      monday: { start: data.mondayStart, end: data.mondayEnd },
      tuesday: { start: data.tuesdayStart, end: data.tuesdayEnd },
      wednesday: { start: data.wednesdayStart, end: data.wednesdayEnd },
      thursday: { start: data.thursdayStart, end: data.thursdayEnd },
      friday: { start: data.fridayStart, end: data.fridayEnd },
      saturday: { start: data.saturdayStart, end: data.saturdayEnd },
      sunday: { start: data.sundayStart, end: data.sundayEnd }
    }
    if (objectData?.sheldule?.id) {
      dispatch(
        updateSchedule({ ...scheduleData, id: objectData?.sheldule?.id })
      )
    } else {
      try {
        const { data } = await axios.post("/object/sheldule.php", scheduleData)
        if (!data?.error) {
          dispatch(updateObject({ ...objectData, sheldule: data?.result[0] }))
        }
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    setSchedule({
      mondayStart: objectData?.sheldule?.monday.start,
      mondayEnd: objectData?.sheldule?.monday.end,
      tuesdayStart: objectData?.sheldule?.tuesday.start,
      tuesdayEnd: objectData?.sheldule?.tuesday.end,
      wednesdayStart: objectData?.sheldule?.wednesday.start,
      wednesdayEnd: objectData?.sheldule?.wednesday.end,
      thursdayStart: objectData?.sheldule?.thursday.start,
      thursdayEnd: objectData?.sheldule?.thursday.end,
      fridayStart: objectData?.sheldule?.friday.start,
      fridayEnd: objectData?.sheldule?.friday.end,
      saturdayStart: objectData?.sheldule?.saturday.start,
      saturdayEnd: objectData?.sheldule?.saturday.end,
      sundayStart: objectData?.sheldule?.sunday.start,
      sundayEnd: objectData?.sheldule?.sunday.end
    })
  }, [objectData])

  useEffect(() => {
    reset(schedule)
  }, [schedule])

  return (
    <>
      <div className="tab-pane p-5 active">
        <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
          <div className="font-medium flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
            <ChevronDown className="w-4 h-4 mr-2" />
            Режим работы
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-x-10 mt-5">
              <div className="col-span-12 2xl:col-span-3 lg:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Понедельник</label>
                  <div className="flex items-center">
                    <input
                      type="time"
                      className="form-control"
                      name="mondayStart"
                      {...register("mondayStart", { required: true })}
                    />
                    <span className="p-2">-</span>
                    <input
                      type="time"
                      className="form-control"
                      name="mondayEnd"
                      {...register("mondayEnd", { required: true })}
                    />
                  </div>
                  {errors.mondayStart || errors.mondayEnd ? (
                    <InvalidFeedback text={"Выберите время"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Вторник</label>
                  <div className="flex items-center">
                    <input
                      type="time"
                      className="form-control"
                      name="tuesdayStart"
                      {...register("tuesdayStart", { required: true })}
                    />
                    <span className="p-2">-</span>
                    <input
                      type="time"
                      className="form-control"
                      name="tuesdayEnd"
                      {...register("tuesdayEnd", { required: true })}
                    />
                  </div>
                  {errors.tuesdayStart || errors.tuesdayEnd ? (
                    <InvalidFeedback text={"Выберите время"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Среда</label>
                  <div className="flex items-center">
                    <input
                      type="time"
                      className="form-control"
                      name="wednesdayStart"
                      {...register("wednesdayStart", { required: true })}
                    />
                    <span className="p-2">-</span>
                    <input
                      type="time"
                      className="form-control"
                      name="wednesdayEnd"
                      {...register("wednesdayEnd", { required: true })}
                    />
                  </div>
                  {errors.wednesdayStart || errors.wednesdayEnd ? (
                    <InvalidFeedback text={"Выберите время"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Четверг</label>
                  <div className="flex items-center">
                    <input
                      type="time"
                      className="form-control"
                      name="thursdayStart"
                      {...register("thursdayStart", { required: true })}
                    />
                    <span className="p-2">-</span>
                    <input
                      type="time"
                      className="form-control"
                      name="thursdayEnd"
                      {...register("thursdayEnd", { required: true })}
                    />
                  </div>
                  {errors.thursdayStart || errors.thursdayEnd ? (
                    <InvalidFeedback text={"Выберите время"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Пятница</label>
                  <div className="flex items-center">
                    <input
                      type="time"
                      className="form-control"
                      name="fridayStart"
                      {...register("fridayStart", { required: true })}
                    />
                    <span className="p-2">-</span>
                    <input
                      type="time"
                      className="form-control"
                      name="fridayEnd"
                      {...register("fridayEnd", { required: true })}
                    />
                  </div>
                  {errors.fridayStart || errors.fridayEnd ? (
                    <InvalidFeedback text={"Выберите время"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Суббота</label>
                  <div className="flex items-center">
                    <input
                      type="time"
                      className="form-control"
                      name="saturdayStart"
                      {...register("saturdayStart", { required: true })}
                    />
                    <span className="p-2">-</span>
                    <input
                      type="time"
                      className="form-control"
                      name="saturdayEn"
                      {...register("saturdayEnd", { required: true })}
                    />
                  </div>
                  {errors.saturdayStart || errors.saturdayEnd ? (
                    <InvalidFeedback text={"Выберите время"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-3 lg:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Воскресенье</label>
                  <div className="flex items-center">
                    <input
                      type="time"
                      className="form-control"
                      name="sundayStart"
                      {...register("sundayStart", { required: true })}
                    />
                    <span className="p-2">-</span>
                    <input
                      type="time"
                      className="form-control"
                      name="sundayEnd"
                      {...register("sundayEnd", { required: true })}
                    />
                  </div>
                  {errors.sundayStart || errors.sundayEnd ? (
                    <InvalidFeedback text={"Выберите время"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-12">
                <button type="submit" className="btn btn-primary w-32 mt-5">
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default GeneralTab
