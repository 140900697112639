import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import axios from "../../utils/axios"

export const getAudios = createAsyncThunk(
  "audios/getAudios",
  async (currentPage,thunkAPI) => {
    try {
      const { data } = await axios.get(`/audio/index.php?page=${currentPage}&count=200`)
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getCategories = createAsyncThunk(
  "audios/getCategories",
  async (thunkAPI) => {
    try {
      const { data } = await axios.get("/category/index.php?category=audio")
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createAudio = createAsyncThunk(
  "audios/createAudio",
  async (audioData, thunkAPI) => {
    try {
      const { data } = await axios.post("/audio/upload.php", audioData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      return data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteAudio = createAsyncThunk(
  "audios/deleteAudio",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/audio/index.php?id=${id}`)
      return { error: data.error, id }
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateAudio = createAsyncThunk(
  "audios/updateAudio",
  async (audioData, thunkAPI) => {
    try {
      const { data } = await axios.post("/audio/update.php", audioData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const createCategory = createAsyncThunk(
  "audios/createCategory",
  async (name, thunkAPI) => {
    try {
      const { data } = await axios.post("/category/index.php", {
        name,
        object: "audio"
      })

      return { data, name }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteCategory = createAsyncThunk(
  "audios/deleteCategory",
  async (id, thunkAPI) => {
    try {
      const { data } = await axios.delete("/category/index.php", {
        data: {
          id: id,
          object: "audio"
        }
      })

      return data
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateCategory = createAsyncThunk(
  "audios/updateCategory",
  async (catData, thunkAPI) => {
    try {
      const { data } = await axios.put("/category/index.php", {
        id: catData.id,
        name: catData.name,
        object: "audio",
        lang: catData.lang
      })

      return { error: data.error, id: catData.id, name: catData.name }
    } catch (error) {
      console.log(error)

      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const initialState = {
  audios: [],
  pages: 1,
  categories: [],
  status: "loading"
}

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Audios
    [getAudios.pending]: (state) => {
      state.audios = []
      state.status = "loading"
    },
    [getAudios.fulfilled]: (state, action) => {
      state.audios = action.payload?.result
      state.pages = action.payload.max_page
      state.status = "loaded"
    },
    [getAudios.rejected]: (state) => {
      state.audios = []
      state.status = "loading"
    },

    // Get Categories
    [getCategories.pending]: (state) => {
      state.categories = []
      state.status = "loading"
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.result
      state.status = "loaded"
    },
    [getCategories.rejected]: (state) => {
      state.categories = []
      state.status = "loading"
    },

    // Create Audio
    [createAudio.pending]: (state) => {
      state.status = "loading"
    },
    [createAudio.fulfilled]: (state, action) => {
      state.status = "created"
      if (!action.payload.error) {
        toast.success("Аудио успешно создана")
      } else {
        toast.error("Ошибка при создании аудио!")
      }
    },
    [createAudio.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании аудио!")
    },

    // Delete Audio
    [deleteAudio.pending]: (state) => {
      state.status = "loading"
    },
    [deleteAudio.fulfilled]: (state, action) => {
      state.status = "loaded"
      state.audios = state.audios?.filter(
        (audio) => audio.id !== action.payload.id
      )

      if (!action.payload.error) {
        toast.success("Аудио успешно удалено")
      } else {
        toast.error("Ошибка при удалении аудио!")
      }
    },
    [deleteAudio.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении аудио!")
    },

    // Update Audio
    [updateAudio.pending]: (state) => {
      state.status = "loading"
    },
    [updateAudio.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Аудио успешно обновлено")
      } else {
        toast.error("Ошибка при обновлении аудио!")
      }
    },
    [updateAudio.rejected]: (state) => {
      toast.error("Ошибка при обновлении аудио!")
    },

    // Create Category
    [createCategory.pending]: (state) => {
      state.status = "loading"
    },
    [createCategory.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Категория успешно создана")
        state.categories.push({
          id: action.payload.data.id,
          name: action.payload.name,
          audio: null
        })
      } else {
        toast.error("Ошибка при создании категории!")
      }
    },
    [createCategory.rejected]: (state) => {
      state.status = "loading"
      toast.error("Ошибка при создании категории!")
    },

    // Delete Category
    [deleteCategory.pending]: (state) => {
      state.status = "loading"
    },
    [deleteCategory.fulfilled]: (state, action) => {
      if (!action.payload.error) {
        toast.success("Категория успешно удалено")
        state.status = "loaded"
        state.categories = state.categories?.filter(
          (audio) => audio.id !== action.payload.id
        )
      } else {
        toast.error("Ошибка при удалении категории!")
      }
    },
    [deleteCategory.rejected]: (state) => {
      state.status = "loaded"
      toast.error("Ошибка при удалении аудио!")
    },

    // Update Category
    [updateCategory.pending]: (state) => {
      state.status = "loading"
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.status = "loaded"
      if (!action.payload.error) {
        toast.success("Категория успешно изменена")
      } else {
        toast.error("Ошибка при обновлении категории!")
      }
    },
    [updateCategory.rejected]: (state) => {
      toast.error("Ошибка при обновлении категории!")
    }
  }
})

export const audioReducer = audioSlice.reducer
