import React from "react"
import iosImg from "../../../../assets/images/ios.svg"
import androidImg from "../../../../assets/images/android.svg"

const Applications = () => {
  return (
    <>
      <div className="col-span-12 lg:col-span-4 mt-8">
        <div className="box p-8 relative overflow-hidden intro-y">
          <div className="leading-8 w-full text-xl -mt-3">
            Скачайте мобильное приложение
          </div>
          <div className="w-full leading-relaxed text-slate-500 mt-3">
            Установите бесплатное мобильное приложение Android или IOS
          </div>
          <div className="w-54 relative mt-6 cursor-pointer tooltip flex">
            <a href="#ios">
              <img src={iosImg} alt="ios" />
            </a>
            <a href="#android" className="ml-2">
              <img src={androidImg} alt="android" />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Applications
