import React, { useState } from "react"
import { FileText, Lock } from "lucide-react"
import GeneralTab from "./GeneralTab"
import ChangePassTab from "./ChangePassTab"

const About = () => {
  const [activeTab, setActiveTab] = useState(1)

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Редактирование профиля</h2>
      </div>
      <div className="pos intro-y grid grid-cols-12 gap-5 mt-5">
        <div className="intro-y col-span-12 lg:col-span-12">
          <div className="post intro-y overflow-hidden box">
            <ul className="post__tabs nav nav-tabs flex-col sm:flex-row bg-slate-200 dark:bg-darkmode-800">
              <li className="nav-item">
                <button
                  className={`nav-link tooltip w-full sm:w-52 py-4 ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  <FileText className="w-4 h-4 mr-2" />
                  Основные данные
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link tooltip w-full sm:w-52 py-4 ${
                    activeTab === 2 ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  <Lock className="w-4 h-4 mr-2"></Lock> Изменить пароль
                </button>
              </li>
            </ul>
            <div className="post__content tab-content">
              {activeTab === 2 ? <ChangePassTab /> : <GeneralTab />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
