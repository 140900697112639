import React from "react"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { ChevronDown } from "lucide-react"
import { yupResolver } from "@hookform/resolvers/yup"

import InvalidFeedback from "../../components/InvalidFeedback"
import { changePassword } from "../../../redux/slices/auth"

const GeneralTab = () => {
  const dispatch = useDispatch()

  const formSchema = Yup.object().shape({
    oldPass: Yup.string()
      .required("Введите старый пароль")
      .min(1, "Длина пароля должна составлять не менее 4 символов"),
    password: Yup.string()
      .required("Введите новый пароль")
      .min(4, "Длина пароля должна составлять не менее 4 символов"),
    passwordConfirm: Yup.string()
      .required("Подтвердите новый пароль")
      .oneOf([Yup.ref("password")], "Пароли должны совпадать")
  })

  const validationOpt = { resolver: yupResolver(formSchema) }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(validationOpt)

  const onSubmit = async (data) => {
    const passData = new FormData()
    passData.append("pass", data.oldPass)
    passData.append("new_pass", data.password)
    passData.append("repeat_pass", data.passwordConfirm)
    dispatch(changePassword(passData))
  }

  return (
    <>
      <div className="tab-pane p-5 active">
        <div className="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
          <div className="font-medium flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
            <ChevronDown className="w-4 h-4 mr-2" />
            Смена пароля
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-x-5 mt-2">
              <div className="col-span-12 2xl:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Старый пароль</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Введите старый пароль"
                    name="oldPass"
                    {...register("oldPass", { required: true })}
                  />
                  {errors.oldPass && (
                    <InvalidFeedback text={errors.oldPass?.message} />
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Новый пароль</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Введите новый пароль"
                    name="password"
                    {...register("password", { required: true })}
                  />
                  {errors.password && (
                    <InvalidFeedback text={errors.password?.message} />
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-4">
                <div className="mb-5">
                  <label className="form-label">Новый пароль (повтор)</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Повторите новый пароль"
                    name="passwordConfirm"
                    {...register("passwordConfirm", { required: true })}
                  />
                  {errors.passwordConfirm && (
                    <InvalidFeedback text={errors.passwordConfirm?.message} />
                  )}
                </div>
              </div>

              <div className="col-span-12 2xl:col-span-12">
                <button type="submit" className="btn btn-primary w-32 mt-2">
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default GeneralTab
