import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPlaylists } from "../../../../redux/slices/video"
import Item from "./Item"

const VideoCreate = () => {
  const dispatch = useDispatch()
  const { playlists } = useSelector((state) => state.video)

  useEffect(() => {
    dispatch(getPlaylists())
  }, [dispatch])

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="intro-y col-span-12 flex flex-wrap xl:flex-nowrap items-center mt-2">
            <h2 className="text-lg font-medium mr-auto mt-2">
              Выберите плейлист с видеороликами
            </h2>
          </div>
        </div>

        {playlists?.length > 0 ? (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <table className="table table-report -mt-2">
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Название плейлиста</th>
                </tr>
              </thead>
              <tbody>
                {playlists?.map((playlist, index) => (
                  <Item key={index} playlist={playlist} />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
            <div className="alert alert-primary show mb-2">
              <div className="flex items-center">
                <div className="font-medium text-lg">
                  Плейлистов пока нет...
                </div>
              </div>
              <div className="mt-3">Обратитесь в техническую поддержку!</div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default VideoCreate
